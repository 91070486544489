import React from "react";
import { SectionArticleButtonListProps } from "../.";
import { LinkFieldType } from "../../../../../contentstack/fields/link";

export interface SectionArticleButtonListContentstackProps {
  buttons: Array<
    Pick<SectionArticleButtonListProps["buttons"][0], "variant"> & {
      link: LinkFieldType;
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  buttons,
}: SectionArticleButtonListContentstackProps): SectionArticleButtonListProps => {
  return {
    buttons: buttons.map(({ link, ...props }) => {
      return {
        ...props,
        ...link,
      };
    }),
  };
};
