import React from "react";
import { SectionArticleHTML5VideoProps } from "../.";
import { VideoData } from "../../../../../contentstack/content-types/html5-videos/transforms/typings";
import { videoDataSourcesToVideoSources } from "../../../../../contentstack/content-types/html5-videos/transforms/video-data-to-video-component";

export interface SectionArticleHTML5VideoContentstackProps
  extends Pick<SectionArticleHTML5VideoProps, "autoplay" | "controls"> {
  videos: VideoData[];
}

export const transformContentstackPropsToSectionProps = ({
  videos,
  ...otherProps
}: SectionArticleHTML5VideoContentstackProps): SectionArticleHTML5VideoProps => {
  return {
    ...otherProps,
    videoSources: videoDataSourcesToVideoSources(videos[0].sources),
  };
};
