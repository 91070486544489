import styled from "styled-components";
import { COLOR_TEXT_BLACK } from "../../../layouts/default/theme";

export const Wrapper = styled.div``;

export const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const Item = styled.li`
  display: inline-block;
  margin: 5px;
`;
