import React, { useState, useRef, useContext } from "react";

import {
  ResponsiveWrapper,
  StyledHexOutline,
  TabLink,
  TabsContainer,
  TabLinksContainer,
  TabLinksInner,
  TabContent,
} from "./style";

import { COLOR_OUTLINE_LIGHT_GREY } from "../../../../../layouts/default/theme";
import { useScrollTo } from "../../../../../utils/hooks";
import StickyOffsetContext from "../../../../../contexts/sticky-offset";

export interface TabProps {
  stickyOffset?: number;
  className?: string;
}

const Tabs: React.FC<TabProps> = ({ stickyOffset, className, children }) => {
  const defaultStickyOffset = useContext(StickyOffsetContext);
  if (stickyOffset === undefined) {
    stickyOffset = defaultStickyOffset;
  }
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabChanged, setActiveTabChanged] = useState<boolean>(false);
  const tabsRef = useRef<HTMLDivElement>(null);

  const renderTabLinks = () => {
    return React.Children.map(children, (item, i) => {
      if (i % 2 === 0) {
        const classSelected = activeTab === i ? "active" : "";
        return (
          <TabLink
            onClick={() => {
              setActiveTab(i);
              setActiveTabChanged(true);
            }}
            className={classSelected}
          >
            {item}
          </TabLink>
        );
      } else {
        return null;
      }
    });
  };

  const renderTabContent = () =>
    React.Children.map(children, (item, i) => (
      <TabContent className={i - 1 === activeTab ? "" : "hide"}>{item}</TabContent>
    ));

  useScrollTo(
    {
      ref: tabsRef,
      offset: -10,
      threshold: 0.35,
      preventCallback: () => !activeTabChanged,
    },
    [activeTab, activeTabChanged],
  );

  return (
    <ResponsiveWrapper className={className}>
      <TabsContainer ref={tabsRef}>
        <TabLinksContainer stickyOffset={stickyOffset}>
          <TabLinksInner>
            <StyledHexOutline isActive clipRightTop={20} strokeColor={COLOR_OUTLINE_LIGHT_GREY} />
            {renderTabLinks()}
          </TabLinksInner>
        </TabLinksContainer>
        {renderTabContent()}
      </TabsContainer>
    </ResponsiveWrapper>
  );
};

export default Tabs;
