import styled, { css } from "styled-components";
import { rem } from "../../../utils/style";
import YouTubeComponent from "@u-wave/react-youtube";
import { MEDIA_MOBILE_ALL, COLUMN_WIDTH } from "../../../layouts/default/theme";
import HexOutlineComponent from "../../../components/hex-outline";
import backgroundPattern from "./assets/bg-pattern.png";
import { ReactComponent as FlourishSvg } from "./assets/flourish.svg";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedItalic,
  cssLocalizedUppercase,
} from "../../../components/css";

export const Wrapper = styled.div``;

export const WrapperInner = styled.div``;

export const Content = styled.div``;

export const Overview = styled.div`
  position: relative;
`;

export const Media = styled.div`
  position: relative;
  padding-top: ${Math.round((500 / 1440) * 10000) / 100 + "%"};

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Details = styled.div<{ hasMedia: boolean }>`
  max-width: 1440px;
  margin: 0 auto;
  padding: 36px ${COLUMN_WIDTH + 20}px 60px;
  text-align: center;

  background-image: ${({ theme }) => (theme && theme.background_color ? "none" : `url(${backgroundPattern})`)};
  background-repeat: repeat-x;
  background-position: left -35px;

  ${({ hasMedia }) =>
    !hasMedia &&
    css`
      padding: 80px ${COLUMN_WIDTH}px 60px;
    `}

  @media ${MEDIA_MOBILE_ALL} {
    background-position: left -96px;
  }
`;

export const Title = styled.div`
  ${cssLocalizedItalic}
  font-weight: 800;
  ${cssLocalizedFontFamilySerif}
  letter-spacing: 0.04em;
  ${cssLocalizedUppercase}
  color: ${({ theme }) => (theme && theme.header_font_color) || "inherit"};
  ${({ theme }) =>
    theme?.font_options?.header_font?.font_family
      ? `font-family: ${theme.font_options.header_font.font_family};`
      : cssLocalizedFontFamilySerif}
`;

export const Blurb = styled.p`
  max-width: 1200px;
  margin: 15px auto 0;
  font-size: ${rem(18)};
  font-weight: bold;
  ${cssLocalizedLineHeight(22 / 18)}
  letter-spacing: 0.06em;

  @media ${MEDIA_MOBILE_ALL} {
    ${cssLocalizedLineHeight(20 / 14)}
    letter-spacing: 0.04em;
  }
`;

export const StyledHexOutline = styled(HexOutlineComponent)``;

export const FlourishWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${860 + 20 + 20 + "px"};
  margin: 0 auto;
  display: flex;
  padding: 0 20px;

  &::before,
  &::after {
    content: "";
    display: block;
    flex: 1 1 100%;
    height: 1px;
    background-color: #c1c1c1;
  }
`;

export const FlourishContainer = styled.div`
  flex: 0 0 auto;
  width: 23px;
`;

export const Flourish = styled(FlourishSvg)`
  position: relative;

  display: block;
  width: 100px;
  height: 13px;

  left: 50%;
  transform: translateX(-50%);

  .st0 {
    fill: none;
    stroke: #c1c1c1;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: #c1c1c1;
    stroke: none;
  }
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  text-align: center;
`;

export const VideoWrapperInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HtmlVideo = styled.video`
  object-fit: cover;
  object-position: center top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

// TODO: this repo needs a central spot for these. I looked around, and it's all over the place. JER
const XLARGE_BREAKPOINT = 1920;
const LARGE_BREAKPOINT = 1600;
const MEDIUM_LARGE_BREAKPOINT = 1400;
const MEDIUM_BREAKPOINT = 1200;
const SMALL_MEDIUM_BREAKPOINT = 1000;
const SMALL_BREAKPOINT = 801; // This is where the header section officially flips to mobile
const XSMALL_BREAKPOINT = 640;
const XXSMALL_BREAKPOINT = 500;
const TINY_BREAKPOINT = 360;

export const StyledYouTube = styled(YouTubeComponent)`
  position: absolute;
  width: 420px;
  height: 240px;
  @media screen and (min-width: ${TINY_BREAKPOINT}px) {
    width: 320px;
    height: 180px;
  }
  @media screen and (min-width: ${XXSMALL_BREAKPOINT}px) {
    width: 426px;
    height: 240px;
  }
  @media screen and (min-width: ${XSMALL_BREAKPOINT}px) {
    width: 540px;
    height: 303.75px;
  }
  @media screen and (min-width: ${SMALL_BREAKPOINT}px) {
    width: 400px;
    height: 225px;
  }
  @media screen and (min-width: ${SMALL_MEDIUM_BREAKPOINT}px) {
    width: 500px;
    height: 281px;
  }
  @media screen and (min-width: ${MEDIUM_BREAKPOINT}px) {
    width: 600px;
    height: 337.5px;
  }
  @media screen and (min-width: ${MEDIUM_LARGE_BREAKPOINT}px) {
    width: 720px;
    height: 405px;
  }
  @media screen and (min-width: ${LARGE_BREAKPOINT}px) {
    width: 854px;
    height: 480px;
  }
  @media screen and (min-width: ${XLARGE_BREAKPOINT}px) {
    width: 960px;
    height: 540px;
  }
`;

const rowLayout = css`
  ${Title} {
    font-size: ${rem(60)};
    ${cssLocalizedLineHeight(0.85)}
  }
`;

const columnLayout = css`
  ${StyledHexOutline} {
    display: none;
  }

  ${WrapperInner} {
    padding: 0;
  }

  ${Content} {
    padding-top: 0;
  }

  ${Media} {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 100%;
  }

  ${Overview} {
    padding: 0;
  }

  ${Details} {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
  }

  ${Title}, ${Blurb} {
    text-align: left;
  }

  ${Title} {
    margin-bottom: 5px;
    font-size: ${rem(20)};
  }

  ${Blurb} {
    margin-top: 5px;
    font-size: ${rem(16)};
  }
`;

const BREAKPOINT = 800;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT + 1 + "px"}) {
    ${rowLayout}
  }

  @media (max-width: ${BREAKPOINT + "px"}) {
    ${columnLayout}
  }
`;
