import React, { useState, useEffect, useRef } from "react";
import { useMediaCanPlay } from "../../../utils/hooks";
import { COLOR_PRIMARY_GOLD, COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";

import { CarouselItem, CarouselItemType } from "./typings";

import {
  CarouselWrapper,
  Introduction,
  Display,
  DisplayHex,
  DisplayItem,
  Nav,
  NavThumb,
  NavThumbHex,
  NavThumbImage,
  CaptionWrapper,
  CaptionItem,
  CaptionTitle,
  CaptionDesc,
} from "./style";

import Carousel from "../../../components/carousel";
import HexOutline from "../../../components/hex-outline";
import Video from "../../../components/video";

const DisplayHexOutline = DisplayHex.withComponent(HexOutline);
const NavThumbHexOutline = NavThumbHex.withComponent(HexOutline);

export interface SectionEventCarouselProps {
  introduction?: React.ReactElement;
  items: CarouselItem[];
  className?: string;
}

const SectionEventCarousel: React.FC<SectionEventCarouselProps> = ({ introduction, items, className }) => {
  const activeVideoRef = useRef<HTMLVideoElement>(null);
  const activeVideoCanPlay = useMediaCanPlay(activeVideoRef);

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const onNavThumbClick = (index: number) => {
    // Update the display
    setActiveItemIndex(index);
  };

  useEffect(() => {
    if (activeVideoRef.current) {
      activeVideoRef.current.play();
    }
  }, [activeItemIndex, activeVideoRef.current, activeVideoCanPlay]);

  return (
    <CarouselWrapper className={className}>
      {introduction && <Introduction>{introduction}</Introduction>}
      <Display>
        {items.map((item, index) => {
          const isActive = index === activeItemIndex;
          if (!isActive) return null;

          return (
            <DisplayItem key={index} className={`${isActive ? "is-active" : ""}`}>
              {item.type === CarouselItemType.IMAGE && <React.Fragment>{item.image}</React.Fragment>}
              {item.type === CarouselItemType.HTML5_VIDEO && (
                <Video
                  lazy={!isActive}
                  objectFit={"contain"}
                  poster={item.videoPoster}
                  videoRef={activeVideoRef}
                  autoPlay
                  muted
                  loop
                  controls
                >
                  {item.videoSources}
                </Video>
              )}
              {item.type === CarouselItemType.YOUTUBE_VIDEO && (
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${item.youtubeVideoId}?autoplay=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
            </DisplayItem>
          );
        })}
        <DisplayHexOutline
          isActive
          offsetAmount={10}
          offsetVertical={-1}
          offsetHorizontal={1}
          clipRightTop={20}
          strokeColor={COLOR_OUTLINE_LIGHT_GREY}
        />
        <DisplayHexOutline
          className={"is-mobile"}
          isActive
          offsetAmount={5}
          offsetVertical={1}
          offsetHorizontal={-1}
          clipRightTop={20}
          strokeColor={COLOR_OUTLINE_LIGHT_GREY}
        />
      </Display>

      <Nav>
        <Carousel onItemClick={onNavThumbClick}>
          {items.map((item, index) => {
            const isActive = index === activeItemIndex;
            return (
              <NavThumb key={index} className={`${isActive ? "is-active" : ""}`}>
                {
                  <NavThumbHexOutline
                    isActive={isActive}
                    clipRightTop={20}
                    strokeColor={COLOR_PRIMARY_GOLD}
                    transitionDelay={0}
                    transitionDuration={500}
                  />
                }
                <NavThumbImage>{item.thumbnail}</NavThumbImage>
              </NavThumb>
            );
          })}
        </Carousel>
      </Nav>

      <CaptionWrapper>
        {items.map((item, index) => {
          const isActive = index === activeItemIndex;
          return (
            <CaptionItem key={index} className={`${isActive ? "is-active" : ""}`}>
              {item.title && <CaptionTitle>{item.title}</CaptionTitle>}
              {item.description && <CaptionDesc>{item.description}</CaptionDesc>}
            </CaptionItem>
          );
        })}
      </CaptionWrapper>
    </CarouselWrapper>
  );
};

export default SectionEventCarousel;
