import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";

import {
  ResponsiveWrapper,
  WrapperInner,
  StyledHexOutline,
  BackgroundImage,
  MobileCategory,
  Content,
  Overview,
  Media,
  Details,
  Title,
  Blurb,
  Meta,
} from "./style";

import { HexTransitionType } from "../../../components/hex-outline";
import { COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";
import { LinkProps } from "../../../components/link";

export interface SectionArticleHeaderProps {
  backgroundImage?: React.ReactElement;
  media?: React.ReactElement;
  aspectRatio?: number;
  title: string;
  blurb: string;
  date: Date;
  category?: LinkProps & {
    name: string;
  };
  authors?: Array<{
    name: string;
    // may support url in the future
  }>;
  link: LinkProps;
  className?: string;
}

const SectionArticleHeader: React.FC<SectionArticleHeaderProps> = ({
  backgroundImage,
  media,
  aspectRatio = 0.5,
  title,
  blurb,
  date,
  category,
  authors,
  link,
  className,
}) => {
  const hexOutlineDelay = 500;
  const hexOutlineDuration = 2000;
  const [hexOutlineVisible, setHexOutlineVisible] = useState<boolean>(false);

  // Hide top border of meta section as soon as hex outline is about to animate in
  useEffect(() => {
    const timeout = setTimeout(() => setHexOutlineVisible(true), hexOutlineDelay + hexOutlineDuration / 2);
    return () => clearTimeout(timeout);
  });

  return (
    <ResponsiveWrapper className={className} data-testid='hero'>
      <WrapperInner>
        <BackgroundImage data-testid='hero:background-image'>{backgroundImage}</BackgroundImage>

        <Content>
          <Overview>
            <Media data-testid='hero:media'>{media}</Media>
            <Details>
              {category && <MobileCategory {...category}>{category.name}</MobileCategory>}
              <Title data-testid='hero:title'>{title}</Title>
              <Blurb data-testid='hero:description'>{blurb}</Blurb>
            </Details>
            <StyledHexOutline
              isActive
              clipRightTop={40}
              strokeColor={COLOR_OUTLINE_LIGHT_GREY}
              accentColor={COLOR_OUTLINE_LIGHT_GREY}
              transition={HexTransitionType.WIRE}
              transitionDuration={hexOutlineDuration}
              transitionDelay={hexOutlineDelay}
            />
          </Overview>

          <ThemeProvider
            theme={{
              borderColor: COLOR_OUTLINE_LIGHT_GREY,
              authorColor: COLOR_OUTLINE_LIGHT_GREY,
            }}
          >
            <Meta
              title={title}
              date={date}
              authors={authors}
              category={category}
              link={link}
              className={hexOutlineVisible ? "hex-outline-visible" : ""}
              testId="hero:meta"
            />
          </ThemeProvider>
        </Content>
      </WrapperInner>
    </ResponsiveWrapper>
  );
};

export default SectionArticleHeader;
