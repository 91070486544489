import React from "react";

import { ResponsiveWrapper, WrapperInner, Media, Html } from "./style";

export type Order = "html-first" | "media-first";

export interface SectionArticleHtmlMediaSplitProps {
  media: React.ReactElement;
  html: React.ReactElement;
  order?: Order;
  className?: string;
}

const SectionArticleHtmlMediaSplit: React.FC<SectionArticleHtmlMediaSplitProps> = ({
  media,
  html,
  order = "media-first",
  className,
}) => {
  return (
    <ResponsiveWrapper className={className} cssOrder={order}>
      <WrapperInner>
        <Media>{media}</Media>
        <Html>{html}</Html>
      </WrapperInner>
    </ResponsiveWrapper>
  );
};

export default SectionArticleHtmlMediaSplit;
