import React from "react";
export interface Props {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
}

const ConditionalWrapper: React.FC<Props> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : <>{children}</>;

export default ConditionalWrapper;
