import styled from "styled-components";
import {
  COLOR_TEXT_LIGHT_GREY,
  COLOR_TEXT_DARK_GREY,
  COLOR_PRIMARY_GOLD_ACTIVE,
  COLOR_BACKGROUND_WHITE,
  COLOR_OUTLINE_LIGHT_GREY,
  MEDIA_MOBILE_ALL,
} from "../../../../../layouts/default/theme";
import HexOutline from "../../../../../components/hex-outline";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../../components/css";

const TAB_LINK_HEIGHT = 54;
const TAB_LINK_PADDING_TOP = 17;

export const StyledHexOutline = styled(HexOutline)``;

export const TabsContainer = styled.div`
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
`;
export const TabLinksContainer = styled.div<{ stickyOffset?: number }>`
  position: sticky;
  top: ${({ stickyOffset }) => (stickyOffset || 0) + "px"};
  z-index: 1;
`;

export const TabLinksInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_BACKGROUND_WHITE};
  height: ${TAB_LINK_HEIGHT}px;
`;

export const TabLink = styled.a`
  box-sizing: border-box;
  position: relative;
  height: ${TAB_LINK_HEIGHT}px;
  padding: ${TAB_LINK_PADDING_TOP}px 8px;
  margin: 0 14px;
  font-size: 10px;
  ${cssLocalizedUppercase}
  ${cssLocalizedLineHeight((TAB_LINK_HEIGHT - TAB_LINK_PADDING_TOP * 2) / 10)}
  letter-spacing: 0.08em;
  color: ${COLOR_TEXT_LIGHT_GREY};
  cursor: pointer;
  &:hover,
  &.active {
    text-decoration: none;
    color: ${COLOR_TEXT_DARK_GREY};
  }
  &.active {
    border-bottom: 3px solid ${COLOR_PRIMARY_GOLD_ACTIVE};
  }
`;

export const TabContent = styled.div`
  padding: 8% 12%;
  border: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
  border-top: 0;
  z-index: 0;

  &.hide {
    display: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  overflow: none;

  @media ${MEDIA_MOBILE_ALL} {
    ${StyledHexOutline} {
      display: none;
    }
    ${TabLinksContainer} {
      overflow: scroll;
      white-space: nowrap;
      height: 52px;
      border: 1px solid ${COLOR_TEXT_LIGHT_GREY};
    }

    ${TabContent} {
      padding: 20px;
    }
  }
`;
