import React from "react";
import { SectionArticleRelatedArticlesProps } from "../.";

export interface SectionArticleRelatedArticlesContentstackProps {
  entryId: string;
}

export const transformContentstackPropsToSectionProps = ({
  entryId,
}: SectionArticleRelatedArticlesContentstackProps): SectionArticleRelatedArticlesProps => {
  return {
    entryId,
  };
};
