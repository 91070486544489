import React from "react";

import { ResponsiveWrapper, ListWrapper } from "./style";
import SectionTitle from "../../../components/section-title";
import { useIntl } from "gatsby-plugin-intl";
import RelatedArticles from "@riotgames/wwpub-components/lib/themes/LoL/components/RelatedArticles";
import getPCSEnvironment from "../../../utils/get-pcs-environment";

export interface SectionArticleRelatedArticlesProps {
  entryId: string;
  className?: string;
}

const SectionArticleRelatedArticles: React.FC<SectionArticleRelatedArticlesProps> = ({ entryId, className }) => {
  const intl = useIntl();
  return (
    <ResponsiveWrapper className={className}>
      <SectionTitle text={intl.formatMessage({ id: "related.title" })} displayOnMobile={true}>
        <ListWrapper>
          <RelatedArticles entryId={entryId} locale={intl.locale} environment={getPCSEnvironment()} from={1} to={3} />
        </ListWrapper>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default SectionArticleRelatedArticles;
