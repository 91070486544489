import React from "react";
import { SectionArticleImageGridProps } from "..";
import { ImageGridItemProps } from "../components/image-grid-item";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";

export interface SectionArticleImageGridContentstackProps {
  images: Array<
    Pick<ImageGridItemProps, "caption" | "value" | "title"> & {
      imageUrl: ImageAssetUrl;
    }
  >;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleImageGridContentstackProps,
): SectionArticleImageGridProps => {
  return {
    images: props.images.map(({ imageUrl, ...otherProps }) => {
      return {
        image: <ComponentImage src={imageUrl} />,
        ...otherProps,
      };
    }),
  };
};
