import styled from "styled-components";
import YouTubeComponent from "@u-wave/react-youtube";
import HexOutlineComponent from "../../../components/hex-outline";
import { COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";

export const YouTubeContainer = styled.div`
  padding: 10px 0px;
  position: relative;
  width: 100%;
  height: auto;
`;

export const YouTubeContainerInner = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 56.25%;
`;

export const YouTube = styled(YouTubeComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SideHighlight = styled.div`
  position: absolute;
  left: calc(100% + 6px);
  top: 29%;
  width: 1px;
  height: 42%;
  border-right: 3px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;

export const YouTubeHexOutline = styled(HexOutlineComponent)`
  left: -10px;
  right: -10px;
  top: 10px;
  bottom: 10px;
`;

const BREAKPOINT = 900;
export const ResponsiveWrapper = styled.div`
  display: flex;
  max-width: 838px;
  margin: 40px auto;

  @media (min-width: ${BREAKPOINT}px) {
    max-width: 838px;
  }

  @media (max-width: ${BREAKPOINT - 1}px) {
    padding: 0 20px;
  }

  html.is-ms-10 & {
    ${SideHighlight},
    ${YouTubeHexOutline} {
      visibility: hidden;
    }
  }
`;
