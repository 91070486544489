import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import { ThemeProvider } from "styled-components";

import { Wrapper } from "./style";

type Props = React.HTMLAttributes<HTMLDivElement>;

export interface RichTextProps {
  content: string;
}

const RichText: React.FC<RichTextProps> = ({ content }) => {
  const intl = useIntl();
  const quotationMarkClose = intl.formatMessage({ id: "quotation-mark.close" });
  const theme = { quotation_mark_close: quotationMarkClose };
  return (
    <ThemeProvider theme={theme}>
      <Wrapper dangerouslySetInnerHTML={{ __html: preProcess(content) }} />
    </ThemeProvider>
  );
};

export default RichText;

function preProcess(raw: string) {
  let processed = raw;
  // Strip inline margins
  processed = processed.replace(/( margin: 0px;|margin: 0px;)/g, "");
  // Add class to floated elements
  processed = processed.replace(/float: left;">/g, 'float: left;" class="left">');
  processed = processed.replace(/float: right;">/g, 'float: right;" class="right">');
  // Add class to two column layout
  processed = processed.replace(/column-count: 2">/g, 'column-count: 2" class="two-column-layout">');

  // Wrap tables in a container that will manage overflow
  processed = processed.replace(/<table/g, '<div class="table-wrapper"><table');
  processed = processed.replace(/<\/table>/g, "</table></div>");

  return processed;
}
