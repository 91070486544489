import styled from "styled-components";
import { MEDIA_TABLET_AND_SMALLER } from "../../../layouts/default/theme";

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  max-height: 600px;
  margin-bottom: 80px;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    max-height: 500px;
    height: 50vh;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;

  > * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
