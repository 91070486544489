import React from "react";
import { COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";
import { HexTransitionType } from "../../../components/hex-outline";

import {
  ResponsiveWrapper,
  WrapperInner,
  StyledHexOutline,
  Content,
  Overview,
  Media,
  Details,
  Title,
  Blurb,
  FlourishWrapper,
  FlourishContainer,
  Flourish,
} from "./style";

export interface SectionEventHeaderProps {
  media?: React.ReactElement;
  title: string;
  blurb?: string;
  className?: string;
}

const SectionEventHeader: React.FC<SectionEventHeaderProps> = ({ media, title, blurb, className }) => {
  const renderMedia =
    media?.props.imageUrl ||
    media?.props.bannerSettings.html5_video_url ||
    media?.props.bannerSettings.mobile_html5_video_url;

  return (
    <ResponsiveWrapper className={className}>
      <WrapperInner>
        <Content>
          <Overview>
            {renderMedia && <Media>{media}</Media>}
            <Details hasMedia={!!media}>
              <Title>{title}</Title>
              <Blurb>{blurb}</Blurb>
            </Details>
            {media?.props.imageUrl && (
              <StyledHexOutline
                isActive
                clipRightTop={40}
                offsetAmount={20}
                offsetHorizontal={-1}
                offsetVertical={-1}
                strokeColor={COLOR_OUTLINE_LIGHT_GREY}
                accentColor={COLOR_OUTLINE_LIGHT_GREY}
                transition={HexTransitionType.WIRE}
                transitionDuration={2000}
                transitionDelay={500}
              />
            )}
            {!media?.props.imageUrl && (
              <FlourishWrapper>
                <FlourishContainer>
                  <Flourish />
                </FlourishContainer>
              </FlourishWrapper>
            )}
          </Overview>
        </Content>
      </WrapperInner>
    </ResponsiveWrapper>
  );
};

export default SectionEventHeader;
