import styled from "styled-components";
import {
  COLOR_TEXT_BLACK,
  COLUMN_WIDTH,
  MOBILE_MARGIN,
  MEDIA_MOBILE_ALL,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_PRIMARY_BLUE_ACTIVE,
} from "../../../layouts/default/theme";
import { rem } from "../../../utils/style";

import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../css";

export const Wrapper = styled.div`
  > * :first-child {
    margin-top: 0;
  }

  > * :last-child {
    margin-bottom: 0;
  }

  width: 100%;
  color: ${({ theme }) => theme.paragraph_font_color || COLOR_TEXT_BLACK};
  font-size: 18px;
  ${cssLocalizedLineHeight(30 / 18)}
  letter-spacing: 0.04em;

  @media ${MEDIA_MOBILE_ALL} {
    font-size: 14px;
    ${cssLocalizedLineHeight(20 / 14)}
    letter-spacing: 0.04em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 40px;

    ${cssLocalizedLineHeight(1)}
    font-weight: 700;
    letter-spacing: 0.05em;

    @media ${MEDIA_MOBILE_ALL} {
      margin-top: 30px;
    }
  }

  h1 {
    font-size: ${rem(36)};

    @media ${MEDIA_MOBILE_ALL} {
      font-size: ${rem(30)};
    }
  }

  h2 {
    font-size: ${rem(24)};

    @media ${MEDIA_MOBILE_ALL} {
      font-size: ${rem(22)};
    }
  }

  h3,
  h4,
  h5 {
    font-size: ${rem(18)};

    @media ${MEDIA_MOBILE_ALL} {
      font-size: ${rem(14)};
    }
  }

  h6 {
    margin-top: 10px;

    font-size: 10px;
    font-weight: 600;
    ${cssLocalizedUppercase}
    letter-spacing: 0.1em;

    @media ${MEDIA_MOBILE_ALL} {
      font-size: 10px;
      ${cssLocalizedLineHeight(14 / 10)}
      letter-spacing: 0.1em;
    }
  }

  p {
    margin: 17px 0;

    &.two-column-layout {
      column-width: 390px;
      column-gap: 70px;
    }

    @media ${MEDIA_MOBILE_ALL} {
      margin: 5px 0;
    }
  }

  a {
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 1px solid ${COLOR_PRIMARY_BLUE_ACTIVE};
    color: ${({ theme }) => theme.paragraph_font_color || COLOR_TEXT_BLACK};
  }

  ul,
  ol {
    padding-left: 30px;
    margin: 20px 0 0;

    li {
      position: relative;

      margin-top: 14px;

      &::before {
        content: "";

        position: absolute;
        display: block;
      }
    }

    ul,
    ol {
      padding-left: 60px;
      margin-top: 0;
    }

    /* nested ul */
    ul {
      li {
        &::before {
          top: 14px;
          right: 100%;

          width: 12px;
          height: 2px;
          margin-right: ${30 - 12}px;

          background: ${({ theme }) => theme.paragraph_font_color || COLOR_TEXT_BLACK};

          transform: none;

          @media ${MEDIA_MOBILE_ALL} {
            top: 10px;
          }
        }
      }
    }

    /* nested ol */
    ol {
      li {
        &::before {
          content: counter(list-counter, lower-alpha) ". ";

          width: auto;
          height: auto;
          background: none;
          transform: none;
        }
      }
    }
  }

  ul {
    list-style: none;

    li {
      &::before {
        top: 11px;
        right: 100%;

        width: 6px;
        height: 6px;
        margin-right: ${30 - 6}px;

        background: ${({ theme }) => theme.paragraph_font_color || COLOR_TEXT_BLACK};

        transform: translateX(2px) rotate(45deg);

        @media ${MEDIA_MOBILE_ALL} {
          top: 8px;
        }
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: list-counter;

    li {
      counter-increment: list-counter;

      &::before {
        content: counter(list-counter) ".";

        top: 4px;
        left: -30px;

        font-size: ${rem(14)};
        font-weight: bold;

        @media ${MEDIA_MOBILE_ALL} {
          top: 0;
        }
      }
    }

    ul {
      li {
        &::before {
          content: "";
        }
      }
    }
  }

  img {
    margin-top: 0.8em;
    max-width: 100%;
  }

  figure {
    margin: 0 0 40px;

    &.left {
      margin-right: 40px;
    }
    &.right {
      margin-left: 40px;
      text-align: right;
    }

    img {
      margin-top: 8px;
    }
  }

  figure + p {
    margin-top: 30px;
  }

  pre {
    font-size: ${rem(14)};
    white-space: normal;
  }

  blockquote {
    position: relative;
    padding-right: ${62 + 10}px;
    padding-bottom: 20px;
    margin: ${COLUMN_WIDTH}px 0 0 0;

    font-size: ${rem(30)};
    ${cssLocalizedLineHeight(34 / 30)}
    ${cssLocalizedFontFamilySerif}
    font-weight: 800;
    ${cssLocalizedItalic}
    ${cssLocalizedUppercase}

    &::after {
      position: absolute;
      right: 0;
      bottom: ${({ theme }) => (theme.quotation_mark_close !== "»" ? "-30px" : "0px")};
      content: "${({ theme }) => theme.quotation_mark_close}";
      display: block;
      font-size: 120px;
      line-height: 0.65;
      color: ${COLOR_OUTLINE_LIGHT_GREY};

      /* use text outline only on browsers that support it */
      @supports ((-webkit-text-stroke-color: ${COLOR_OUTLINE_LIGHT_GREY}) and (-webkit-text-fill-color: transparent)) {
        color: unset;
        text-shadow: unset;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: ${COLOR_OUTLINE_LIGHT_GREY};
        -webkit-text-stroke-width: 1px;
      }
    }

    @media ${MEDIA_MOBILE_ALL} {
      padding-right: 25px;
      padding-bottom: 10px;
      margin-top: ${MOBILE_MARGIN}px;

      font-size: ${rem(18)};
      ${cssLocalizedLineHeight(18 / 16)}

      &::after {
        bottom: ${({ theme }) => (theme.quotation_mark_close !== "»" ? "-15px" : "0px")};
        font-size: 60px;
      }
    }
  }

  figure {
    figcaption {
      margin-top: 12px;

      font-size: 10px;
      ${cssLocalizedLineHeight(1.2)}
      ${cssLocalizedUppercase}
      letter-spacing: 0.1em;

      @media ${MEDIA_MOBILE_ALL} {
        font-size: 10px;
        ${cssLocalizedLineHeight(14 / 10)}
        letter-spacing: 0.1em;
      }
    }
  }

  hr {
    margin: 30px 0;
    border: 0;
    border-bottom: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;
    margin: 2em 0;

    > table {
      /* WORKAROUND: seems like an extra pixel causes overflow */
      width: calc(100% - 2px);
      min-width: 400px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    font-size: 14px;
    ${cssLocalizedLineHeight(18 / 14)}
    letter-spacing: 0.06em;

    @media ${MEDIA_MOBILE_ALL} {
      font-size: 14px;
      ${cssLocalizedLineHeight(20 / 14)}
      letter-spacing: 0.04em;
    }

    thead {
      font-size: ${rem(12)};
      letter-spacing: 0.1em;
      font-weight: 600;
      ${cssLocalizedUppercase}

      @media ${MEDIA_MOBILE_ALL} {
        font-size: ${rem(12)};
        ${cssLocalizedLineHeight(1.4)}
        letter-spacing: 0.1em;
      }

      td,
      th {
        border: none;
        padding: 10px 20px;
      }
    }

    td {
      padding: 15px 20px;
      border: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
    }
  }
`;
