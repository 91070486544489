import React from "react";
import { SectionArticleFullWidthImageProps } from "..";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";

export interface SectionArticleFullWidthImageContentstackProps {
  imageUrl: ImageAssetUrl;
}

export const transformContentstackPropsToSectionProps = ({
  imageUrl,
  ...otherProps
}: SectionArticleFullWidthImageContentstackProps): SectionArticleFullWidthImageProps => {
  return {
    image: <ComponentImage src={imageUrl} />,
    ...otherProps,
  };
};
