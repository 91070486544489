import React, { ReactElement } from "react";

import { Wrapper, ImageContainer } from "./style";

export interface SectionArticleFullWidthImageProps {
  image: ReactElement;
  className?: string;
}

const Section: React.FC<SectionArticleFullWidthImageProps> = ({ image, className }) => {
  return (
    <Wrapper className={className}>
      <ImageContainer>{image}</ImageContainer>
    </Wrapper>
  );
};

export default Section;
