import React, { ReactElement, useState, useRef } from "react";
import { useResize } from "../../../../../utils/hooks";

import {
  StyledAccordionItem,
  StyledAccordionItemButtonIconPlus,
  StyledAccordionItemButtonIconMinus,
  StyledAccordionItemHeading,
  StyledAccordionItemButton,
  StyledAccordionItemPanel,
  Content,
} from "./style";

export interface Props {
  title: string;
  content: ReactElement;
}

const Panel: React.FC<Props> = ({ title, content }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const itemPanelRef = useRef<HTMLDivElement>(null);

  useResize(() => {
    if (!itemPanelRef.current) return;
    setMaxHeight(itemPanelRef.current.offsetHeight);
  }, [itemPanelRef]);

  return (
    <StyledAccordionItem>
      <StyledAccordionItemHeading>
        <StyledAccordionItemButton>
          <StyledAccordionItemButtonIconPlus />
          <StyledAccordionItemButtonIconMinus />
          {title}
        </StyledAccordionItemButton>
      </StyledAccordionItemHeading>
      <StyledAccordionItemPanel style={{ maxHeight }}>
        <Content ref={itemPanelRef}>{content}</Content>
      </StyledAccordionItemPanel>
    </StyledAccordionItem>
  );
};

export default Panel;
