import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Button from "../../../components/button-small";
import { LinkProps } from "../../../components/link";
import { Item, List, Wrapper } from "./style";


export interface SectionArticleButtonListProps {
  buttons: Array<
    LinkProps & {
      variant?: "primary" | "secondary";
      text: string;
    }
  >;
  className?: string;
}

const SectionArticleButtonList: React.FC<SectionArticleButtonListProps> = ({ buttons, className }) => {
  /**
   * TODO: Refactor <Button> to use values from theme instead of passing colors
   * as attributes.
   */
  const themeContext = useContext(ThemeContext);
  const { primary_button_settings: primary, secondary_button_settings: secondary } = themeContext || {};
  const { background_color: primaryBG } = primary || {};
  const { background_color: secondaryBG } = secondary || {};

  return (
    <Wrapper className={className}>
      <List>
        {buttons.map(({ text, url, internal, variant = "primary" }, index) => {
          return (
            <Item key={index}>
              <Button
                variant={variant}
                link={{ url, internal }}
                fillColor={variant === "primary" ? primaryBG : secondaryBG}
                strokeColor="transparent"
              >
                {text}
              </Button>
            </Item>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default SectionArticleButtonList;
