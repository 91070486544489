import styled from "styled-components";
import { cssLocalizedUppercase } from "../css";
import { rem, easing } from "../../utils/style";
import { COLOR_PRIMARY_BLUE_ACTIVE, COLOR_PRIMARY_BLUE } from "../../layouts/default/theme";
import { rgba } from "polished";

export const Wrapper = styled.div`
  &.pswp {
    /* ensure appears above riotbar */
    z-index: ${3000000 + 1};

    /* position absolutely so that any overflow can be scrollable by body */
    position: absolute !important;
  }

  .pswp__top-bar,
  .pswp__ui--fit .pswp__top-bar {
    background-color: ${rgba("#000913", 0.8)};
    height: 60px;
  }

  .pswp__counter {
    display: none;
  }

  .pswp__caption__center {
    text-align: center;
    ${cssLocalizedUppercase}
    letter-spacing: 0.1em;
  }

  .pswp__button--next,
  .pswp__button--previous,
  .pswp__button--close {
    height: 60px;
    background-image: none;
    background-color: transparent;
  }

  .pswp__button--close {
    width: 60px;
  }
`;

export const CloseIcon = styled.svg`
  display: block;
  width: 12px;
  height: 12px;

  fill: none;
  stroke: ${COLOR_PRIMARY_BLUE};
  stroke-miterlimit: 10;
`;

export const Separator = styled.span`
  display: block;
  width: 1px;
  height: 40px;
  float: right;
  margin: 10px 35px;
  background-color: #33313b;
`;

export const GalleryDockNavigationButton = styled.span`
  /* Reset */
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;

  /* Style */
  position: relative;

  display: block;
  width: ${rem(40)};
  height: ${rem(32)};
  margin-right: ${rem(5)};

  cursor: pointer;

  transition: opacity 0.2s ${easing.easeInOutCubic};

  &.is-disabled {
    opacity: 0.3;
    cursor: normal;
    pointer-events: none;
  }
`;

export const ArrowIcon = styled.svg`
  display: block;
  width: 40%;
  height: 100%;
  margin: 0 30%;

  fill: ${COLOR_PRIMARY_BLUE};
  stroke: ${COLOR_PRIMARY_BLUE};

  transition: fill 0.2s ${easing.easeInOutCubic}, stroke 0.2s ${easing.easeInOutCubic};

  ${GalleryDockNavigationButton}:hover & {
    fill: ${COLOR_PRIMARY_BLUE_ACTIVE};
    stroke: ${COLOR_PRIMARY_BLUE_ACTIVE};
  }
`;
