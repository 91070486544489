import React, { useEffect, useRef, useState } from "react";
import { Section, Video, VideoContainer } from "./style";
import { useInView, useMedia } from "../../../utils/hooks";

export interface SectionEventFooterProps {
  footer_video_enabled?: boolean;
  footer_html5_video_url?: string;
  footer_mobile_html5_video_url?: string;
}

const SectionEventFooter: React.FC<SectionEventFooterProps> = ({
  footer_video_enabled,
  footer_html5_video_url,
  footer_mobile_html5_video_url,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [html5VideoUrl, setHtml5VideoUrl] = useState<string>("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useMedia(`(max-width: 800px)`, (match) => {
    setIsMobile(match);
  });

  useEffect(() => {
    if (isMobile && footer_mobile_html5_video_url) {
      setHtml5VideoUrl(footer_mobile_html5_video_url);
    } else if (!isMobile && footer_html5_video_url) {
      setHtml5VideoUrl(footer_html5_video_url);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (inView) videoRef.current.play();
    else if (!inView) videoRef.current.pause();
  }, [inView, videoRef.current]);

  if (!footer_video_enabled) {
    return null;
  }

  return (
    <Section>
      <VideoContainer ref={ref}>
        <Video key={html5VideoUrl} ref={videoRef} muted loop playsInline autoPlay>
          <source src={html5VideoUrl} type="video/mp4" />
        </Video>
      </VideoContainer>
    </Section>
  );
};

export default SectionEventFooter;
