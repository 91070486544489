import styled, { css } from "styled-components";
import { COLOR_BACKGROUND_WHITE, COLOR_TEXT_BLACK, MEDIA_MOBILE_ALL } from "../../../../../layouts/default/theme";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../../components/css";

export const Wrapper = styled.div`
  position: inline-block;
  min-width: 217px;
  * {
    color: ${({ theme }) => theme.paragraph_font_color || COLOR_TEXT_BLACK};
  }
`;

export const ImageContainer = styled.div<{ dimensions?: [number, number] }>`
  position: relative;
  display: block;
  width: 100%;
  height: auto !important; /* IE10/11 - prevent object-fit polyfill from adjusting the height */
  min-width: 220px;
  overflow: hidden;
  padding-bottom: ${({ dimensions }) => dimensions && Math.round((dimensions[1] / dimensions[0]) * 10000) / 100 + "%"};
  max-width: ${({ dimensions }) => dimensions && dimensions[0] + "px"};

  html.is-safari & {
    /* prevent flickering while scrolling */
    transform: translate3d(0, 0, 0);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 10%;
    padding-top: 10%;
    background-color: ${({ theme }) => theme.background_color || COLOR_BACKGROUND_WHITE};
    transform: translate(50%, -50%) rotate(45deg);
  }

  * {
    display: block;
    width: 100%;
    ${({ dimensions }) =>
      dimensions &&
      css`
        position: absolute;
        top: 0;
        left: 0;
      `};
  }
`;

export const InfoContainer = styled.div`
  padding-top: 10px;
`;

export const Title = styled.div`
  font-size: 18px;
  ${cssLocalizedLineHeight(28 / 18)}
  letter-spacing: 0.04em;
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;
  ${cssLocalizedLineHeight(18 / 14)}
  ${cssLocalizedUppercase}
`;

export const Caption = styled.div`
  font-size: 10px;
  ${cssLocalizedLineHeight(14 / 10)}
  ${cssLocalizedUppercase}
  letter-spacing: 0.1em;
`;
