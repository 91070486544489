import React, { useRef, useEffect, useState, useCallback, useContext } from "react";

import {
  ResponsiveWrapper,
  VideoContainer,
  VideoContainerInner,
  StyledVideo,
  SideHighlight,
  VideoHexOutline,
} from "./style";

import { useInView } from "../../../utils/hooks";
import RiotLocaleContext from "../../../contexts/riot-locale";
import { VideoSource } from "../../../components/video";

export interface SectionArticleHTML5VideoProps {
  videoSources: VideoSource[];
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  poster?: string;
  locale?: string;
  className?: string;
}

const SectionArticleHTML5Video: React.FC<SectionArticleHTML5VideoProps> = ({
  videoSources,
  autoplay = false,
  controls = true,
  loop = false,
  poster = "",
  locale,
  className,
}) => {
  const localeContext = useContext(RiotLocaleContext);
  if (locale === undefined) {
    locale = localeContext;
  }
  const [paused, setPaused] = useState<boolean>(true);
  const [pausedInView, setPausedInView] = useState<boolean>(false);
  const [player, setPlayer] = useState<HTMLVideoElement | null>(null);
  const videoRef = useRef<HTMLDivElement>(null);
  const [inViewVideoRef, inViewVideo] = useInView();

  // play/pause video when in view
  useEffect(() => {
    // user manually paused video when in view, so don't restart it
    if (!autoplay || pausedInView) return;

    // start playing video when in view
    setPaused(!inViewVideo);
    if (player) player.pause();
  }, [player, inViewVideo]);

  // keep track when user manually paused video
  useEffect(() => {
    if (!autoplay) return;

    if (paused) {
      if (inViewVideo) {
        // paused by user while watching video
        setPausedInView(true);
      } else {
        // this was paused due to video coming out of view
      }
    } else {
      // started to play, so reset state
      setPausedInView(false);
    }
  }, [paused]);

  // ensure video is playing when unpaused
  useEffect(() => {
    if (!player) return;
    const p = player;

    // try playing video as soon as unpaused
    if (!paused) p.play();
  }, [player, paused]);

  // memoized callbacks
  const onReadyVideo = useCallback((event) => setPlayer(event.target), []);
  const onPauseVideo = useCallback(() => setPaused(true), []);
  const onPlayingVideo = useCallback(() => setPaused(false), []);

  return (
    <ResponsiveWrapper className={className}>
      <VideoContainer ref={inViewVideoRef}>
        <VideoContainerInner ref={videoRef}>
          <StyledVideo
            controls={controls}
            loop={loop}
            autoPlay={autoplay}
            muted={autoplay}
            poster={poster}
            lang={locale}
            onCanPlay={onReadyVideo}
            onPause={onPauseVideo}
            onPlaying={onPlayingVideo}
          >
            {videoSources}
          </StyledVideo>
          <SideHighlight />
          <VideoHexOutline isActive={true} clipRightTop={20} />
        </VideoContainerInner>
      </VideoContainer>
    </ResponsiveWrapper>
  );
};

export default SectionArticleHTML5Video;
