import styled from "styled-components";
import { DiamondCombined } from "../../../../../assets/rings";

import {
  COLOR_PRIMARY_GOLD,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_TEXT_DARK_GREY,
} from "./../../../../../layouts/default/theme";

import HexOutline from "../../../../../components/hex-outline";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../../components/css";

export const Wrapper = styled.div``;

export const StyledHexOutline = styled(HexOutline)`
  bottom: 2px;
  z-index: 0;
`;

export const AvatarContainer = styled.div`
  position: relative;
  width: 130px;
  height: 140px;
  margin: 0 auto;
`;

export const Ring = styled(DiamondCombined)`
  display: block;
  position: relative;
  fill: ${COLOR_PRIMARY_GOLD};
`;

export const Avatar = styled.div`
  position: absolute;
  overflow: hidden;
  top: 14%;
  left: 50%;
  width: 84.5%;
  padding-bottom: 84.5%;
  border-radius: 50%;

  transform: translateX(-50%);

  > * {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Name = styled.div`
  margin-top: 15%;
  color: ${COLOR_PRIMARY_GOLD};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.01em;
  z-index: 3;
`;

export const TitleAndAuthor = styled.div`
  padding: 8px 0 14px;
  color: ${COLOR_TEXT_DARK_GREY};
  font-size: 10px;
  ${cssLocalizedUppercase}
  letter-spacing: 0.1em;
  z-index: 3;
`;

export const Bio = styled.div`
  color: ${COLOR_TEXT_DARK_GREY};
  font-size: 14px;
  ${cssLocalizedLineHeight(18 / 14)}
  letter-spacing: 0.06em;
  z-index: 3;
`;

export const Underline = styled.div`
  margin: 0 auto;
  position: absolute;
  width: 124px;
  margin-left: ${-124 / 2}px;
  left: 50%;
  top: calc(100% - 4px);
  border-bottom: 3px solid ${COLOR_OUTLINE_LIGHT_GREY};
  z-index: 3;
`;
