import React from "react";
import { SectionArticleAccordionProps } from "..";
import { RichTextFieldType } from "../../../../../contentstack/fields/rich-text";
import richTextToReactElement from "../../../../../contentstack/utils/rich-text-to-react-element";

export interface SectionArticleAccordionContentstackProps {
  sections: Array<{
    title: string;
    content: RichTextFieldType;
  }>;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleAccordionContentstackProps,
): SectionArticleAccordionProps => {
  return {
    sections: props.sections.map(({ content, ...otherProps }) => {
      return {
        content: richTextToReactElement(content),
        ...otherProps,
      };
    }),
  };
};
