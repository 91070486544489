import React from "react";
import ArticleTemplate from "../../../../../lib/templates/article";
import { SectionType, Section } from "../../../../../lib/templates/article/typings";

import { transformContentstackPropsToSectionProps as transformAccordion } from "../../../../../lib/sections/article/accordion/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformAudio } from "../../../../../lib/sections/article/audio/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformAuthorList } from "../../../../../lib/sections/article/author-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformButtonList } from "../../../../../lib/sections/article/button-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformCallOut } from "../../../../../lib/sections/article/call-out/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformGallery } from "../../../../../lib/sections/article/gallery/transforms/contentstack";
import {
  SectionArticleHeaderContentstackProps,
  transformContentstackPropsToSectionProps as transformHeader,
} from "../../../../../lib/sections/article/header/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformHtml } from "../../../../../lib/sections/article/html/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformHtmlMediaSplit } from "../../../../../lib/sections/article/html-media-split/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformRelatedArticles } from "../../../../../lib/sections/article/related-articles/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformSeparator } from "../../../../../lib/sections/article/separator/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformShare } from "../../../../../lib/sections/article/share/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformTabList } from "../../../../../lib/sections/article/tab-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformTweetList } from "../../../../../lib/sections/article/tweet-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformImageGrids } from "../../../../../lib/sections/article/image-grid/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformFullWidthImage } from "../../../../../lib/sections/article/full-width-image/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPromo } from "../../../../../lib/sections/promo/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformYoutube } from "../../../../../lib/sections/article/youtube/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformHtml5Video } from "../../../../../lib/sections/article/html5-video/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformEventHeader } from "../../../../../lib/sections/event/header/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformEventCarousel } from "../../../../../lib/sections/event/carousel/transforms/contentstack";

import { TemplateContext, ContentstackSection } from "./typings";
import Meta from "../../../../../lib/components/meta";
import { openGraphImageUrl, twitterImageUrl } from "../../../../../lib/assets/share";
import { useIntl } from "gatsby-plugin-intl";
import { useMetaTitle } from "../../../../utils/use-meta-title";
import { graphql, PageProps } from "gatsby";
import transformAllInternalArticlesQueryNodeToDefaultTemplateContextData from "../../transforms/all-internal-articles-query-node-to-default-template-context-data";

interface PageData {
  // TODO: unravel types.
  all: {
    nodes: any;
  };
  configuration: any;
}

type Props = PageProps<PageData, TemplateContext>;

const byPublishedLocale =
  (locale: string) =>
  (node: any): boolean =>
    node.publish_details.locale === locale;

const ContentType: React.FC<Props> = ({ pageContext, path, data: __data }) => {
  const intl = useIntl();
  const language = pageContext.language;
  const byContextLocale = byPublishedLocale(language);
  const node = __data.all.nodes.filter(byContextLocale)[0];
  const data = transformAllInternalArticlesQueryNodeToDefaultTemplateContextData(node);
  const configurationNode = __data.configuration.edges[0].node;

  const headerProps: SectionArticleHeaderContentstackProps = {
    url: {
      url: path,
      internal: true,
    },
    authors: !data.authors ? undefined : data.authors.map(({ name }) => name),
    backgroundImageUrl: data.imageUrl,
    category: data.category,
    date: data.published_date,
    blurb: data.description,
    title: data.title,
    imageUrl: data.imageUrl,
  };

  const headerSection: ContentstackSection = {
    type: SectionType.ARTICLE_HEADER,
    props: headerProps,
  };

  const tagsSection: ContentstackSection = {
    type: SectionType.ARTICLE_TAGS,
    props: {
      tags: data.article_tags,
      patchNotes: false,
      tagsTitle: configurationNode.articles.tags_title,
    },
  };

  const shareSection: ContentstackSection = {
    type: SectionType.ARTICLE_SHARE,
    props: {
      title: intl.formatMessage({ id: "share-this-article.title" }),
      url: {
        url: path,
        internal: true,
      },
    },
  };

  const sections = [headerSection, ...data.sections, tagsSection, shareSection];

  sections.push({
    type: SectionType.ARTICLE_RELATED_ARTICLES,
    props: {
      entryId: data.uid,
    },
  });

  const title = useMetaTitle(data.title);
  return (
    <React.Fragment>
      <Meta
        title={title}
        description={data.description}
        openGraph={{
          image: {
            url: data.imageUrl ? data.imageUrl : openGraphImageUrl,
          },
        }}
        twitter={{
          image: {
            url: data.imageUrl ? data.imageUrl : twitterImageUrl,
          },
        }}
      />
      <ArticleTemplate sections={dataSectionsToArticleSections(sections)}></ArticleTemplate>
    </React.Fragment>
  );
};
export default ContentType;

export const dataSectionsToArticleSections = (dataSections: ContentstackSection[]): Section[] => {
  return dataSections
    .map((csSection, index) => {
      let props;

      switch (csSection.type) {
        case SectionType.ARTICLE_ACCORDION:
          props = transformAccordion(csSection.props);
          break;

        case SectionType.ARTICLE_AUDIO:
          props = transformAudio(csSection.props);
          break;

        case SectionType.ARTICLE_AUTHOR_LIST:
          props = transformAuthorList(csSection.props);
          break;

        case SectionType.ARTICLE_BUTTON_LIST:
          props = transformButtonList(csSection.props);
          break;

        case SectionType.ARTICLE_CALL_OUT:
          props = transformCallOut(csSection.props);
          break;

        case SectionType.ARTICLE_GALLERY:
          props = transformGallery(csSection.props);
          break;

        case SectionType.ARTICLE_HEADER:
          props = transformHeader(csSection.props);
          break;

        case SectionType.ARTICLE_HTML:
          props = transformHtml(csSection.props);
          break;

        case SectionType.ARTICLE_HTML_MEDIA_SPLIT:
          props = transformHtmlMediaSplit(csSection.props);
          break;

        case SectionType.ARTICLE_RELATED_ARTICLES:
          props = transformRelatedArticles(csSection.props);
          break;

        case SectionType.ARTICLE_SEPARATOR:
          props = transformSeparator(csSection.props);
          break;

        case SectionType.ARTICLE_SHARE:
          props = transformShare(csSection.props);
          break;

        case SectionType.ARTICLE_TAGS:
          props = csSection.props;
          break;

        case SectionType.ARTICLE_TAB_LIST:
          props = transformTabList(csSection.props);
          break;

        case SectionType.ARTICLE_TWEET_LIST:
          props = transformTweetList(csSection.props);
          break;

        case SectionType.ARTICLE_IMAGE_GRID:
          props = transformImageGrids(csSection.props);
          break;

        case SectionType.ARTICLE_FULL_WIDTH_IMAGE:
          props = transformFullWidthImage(csSection.props);
          break;

        case SectionType.ARTICLE_PROMO:
          props = transformPromo(csSection.props);
          break;

        case SectionType.ARTICLE_YOUTUBE:
          props = transformYoutube(csSection.props);
          break;

        case SectionType.ARTICLE_HTML5VIDEO:
          props = transformHtml5Video(csSection.props);
          break;

        case SectionType.EVENT_HEADER:
          props = transformEventHeader(csSection.props);
          break;

        case SectionType.EVENT_CAROUSEL:
          props = transformEventCarousel(csSection.props);
          break;

        case SectionType.EVENT_FOOTER:
          props = csSection.props;
          break;

        case SectionType.EMAIL_FORM:
          props = csSection.props;
          break;
      }

      if (!props) {
        console.warn(`Unable to transform section. (type: ${csSection.type})`);
        return null;
      }

      return {
        type: csSection.type,
        key: `section_${index}`,
        props,
      };
    })
    .filter((section): section is Section => section !== null);
};

export const query = graphql`
  # If the page query cache needs to change with context changes, then we need
  # $path and a query for contextChangeNotInvalidingQueryWorkaround
  # Since this page only uses locale and url, and those are essentially the query
  # cache key anyways, then we don't need to add this hack.
  #
  # query($language: String!, $url: String!, $path: String!) {
  # use our workaround and alias null result to something short (_)
  # that doesn't expose what it does and saves few bytes
  #_: contextChangeNotInvalidingQueryWorkaround(path: $path)

  query ($language: String!, $url: String!) {
    all: allContentstackArticles(
      filter: { publish_details: { locale: { eq: $language } }, url: { url: { eq: $url } } }
    ) {
      nodes {
        publish_details {
          locale
        }
        ...FullArticleFragment
      }
    }
    configuration: allContentstackWebsiteConfiguration(filter: { publish_details: { locale: { eq: $language } } }) {
      edges {
        node {
          articles {
            tags_title
          }
        }
      }
    }
  }
`;

export const FullArticleFragment = graphql`
  fragment FullArticleFragment on Contentstack_articles {
    id
    uid
    url {
      url
    }
    title
    date
    description
    category {
      title
      machine_name
      url {
        url
      }
    }
    banner {
      url
    }
    article_body {
      accordion {
        accordion_configuration {
          accordion_title
          accordion_description
        }
      }
      audio {
        audio_file {
          url
        }
        caption
      }
      author_bio {
        author_selection {
          author_bio
          job_title
          full_name
          title
          avatar {
            url
          }
        }
      }
      call_out {
        body
        image {
          url
        }
        external_link
        open_in_new_window
      }
      html5_video {
        autoplay
        controls
        html5_video_selection {
          title
          video {
            file {
              url
              content_type
            }
            height
            width
          }
        }
      }
      image_gallery {
        title
        image_selection {
          image_title
          select_image {
            url
          }
        }
      }
      images {
        images {
          image {
            url
          }
          caption
          title
          value
          external_link
          open_in_new_window
        }
      }
      media_carousel {
        introduction
        items {
          title
          description
          thumbnail {
            uid
            url
          }
          image {
            uid
            url
          }
          html5_video {
            title
            static_image {
              url
            }
            video {
              file {
                url
                content_type
              }
            }
          }
          youtube_video_id
        }
      }
      promo_module {
        promo_module_selection {
          uid
          promo_title
          subtitle
          description
          hide_title
          include_description
          promo_reference {
            __typename
            ... on Contentstack_articles {
              ...ContentstackArticleExcerpt
            }
          }
          promo_full_width_banner {
            url
            uid
          }
        }
      }
      rich_text_editor {
        rich_text_editor
        side_image {
          order
          image {
            url
          }
        }
      }
      separator {
        type
        custom_divider {
          url
        }
      }
      video_youtube {
        video_id
      }
      tweet_embed {
        tweet {
          tweet_id
        }
      }
      tabs {
        tab_configuration {
          tab_content
          tab_name
        }
      }
      cta_list {
        items {
          link {
            href
            title
          }
          style
        }
      }
    }
    author {
      title
    }
    article_type
    article_tags {
      title
      machine_name
      is_hidden
      url {
        url
      }
    }
  }
`;
