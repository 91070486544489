import styled from "styled-components";
import { Accordion } from "react-accessible-accordion";
import { COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";

export const Wrapper = styled.div`
  max-width: 840px;
  margin: 0 auto;

  @media (max-width: 880px) {
    padding: 20px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  border-bottom: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;
