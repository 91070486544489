import React from "react";

import { ResponsiveWrapper, ContentContainer, ImageContainer, BodyContainer, HighlightStrip } from "./style";
import { StyledHexOutline } from "../../news/social/style";
import { COLOR_OUTLINE_LIGHT_GREY } from "./../../../../lib/layouts/default/theme";
import ConditionalWrapper from "../../../components/conditional-wrapper";

export interface SectionArticleCallOutProps {
  className?: string;
  image?: React.ReactElement;
  body: React.ReactElement;
  external_link?: string;
  open_in_new_window?: boolean;
}

const SectionArticleCallOut: React.FC<SectionArticleCallOutProps> = ({
  className,
  image,
  body,
  external_link,
  open_in_new_window,
}) => {
  return (
    <ConditionalWrapper
      condition={!!external_link}
      wrapper={(children) => (
        <a href={external_link} target={open_in_new_window ? "_blank" : undefined}>
          {children}
        </a>
      )}
    >
      <ResponsiveWrapper className={className}>
        <ContentContainer>
          <ImageContainer>{image}</ImageContainer>
          <BodyContainer>{body}</BodyContainer>
          <HighlightStrip />
          <StyledHexOutline isActive clipRightTop={20} offsetVertical={-1} strokeColor={COLOR_OUTLINE_LIGHT_GREY} />
        </ContentContainer>
      </ResponsiveWrapper>
    </ConditionalWrapper>
  );
};

export default SectionArticleCallOut;
