import React from "react";

import { ResponsiveWrapper } from "./style";

export interface SectionArticleHtmlProps {
  content: React.ReactElement;
  className?: string;
}

const SectionArticleHtml: React.FC<SectionArticleHtmlProps> = ({ content, className }) => {
  return <ResponsiveWrapper className={className}>{content}</ResponsiveWrapper>;
};

export default SectionArticleHtml;
