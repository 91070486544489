import React from "react";
import { SectionArticleHtmlMediaSplitProps } from "../.";
import { RichTextFieldType } from "../../../../../contentstack/fields/rich-text";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import richTextToReactElement from "../../../../../contentstack/utils/rich-text-to-react-element";
import ComponentImage from "../../../../components/image";

export interface SectionArticleHtmlMediaSplitContentstackProps
  extends Pick<SectionArticleHtmlMediaSplitProps, "order"> {
  mediaUrl: ImageAssetUrl;
  html: RichTextFieldType;
}

export const transformContentstackPropsToSectionProps = ({
  mediaUrl,
  html,
  ...otherProps
}: SectionArticleHtmlMediaSplitContentstackProps): SectionArticleHtmlMediaSplitProps => {
  return {
    media: <ComponentImage src={mediaUrl} />,
    html: richTextToReactElement(html),
    ...otherProps,
  };
};
