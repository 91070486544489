import React from "react";
import Tabs from "./components/tabs";

import { Wrapper } from "./style";

export interface ArticleTab {
  title: string;
  body: React.ReactElement;
}

export interface SectionArticleTabListProps {
  tabs: ArticleTab[];
  className?: string;
}

const SectionArticleTabList: React.FC<SectionArticleTabListProps> = ({ tabs, className }) => {
  const reducer = (acc: React.ReactElement[], cur: ArticleTab) => [...acc, <>{cur.title}</>, cur.body];

  return (
    <Wrapper className={className}>
      <Tabs>{tabs && tabs.reduce(reducer, []).map((element, index) => <span key={index}>{element}</span>)}</Tabs>
    </Wrapper>
  );
};

export default SectionArticleTabList;
