import React, { useContext } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import Carousel from "../../../components/carousel";
import RiotLocaleContext from "../../../contexts/riot-locale";
import { ResponsiveWrapper, TweetContainer } from "./style";

export interface SectionArticleTweetListProps {
  tweets: string[];
  locale?: string;
  className?: string;
}

const SectionArticleTweetList: React.FC<SectionArticleTweetListProps> = ({ tweets, locale, className }) => {
  const localeContext = useContext(RiotLocaleContext);
  if (locale === undefined) {
    locale = localeContext;
  }
  return (
    <ResponsiveWrapper className={className}>
      <Carousel className={"carousel"}>
        {tweets.map((id) => (
          <TweetContainer key={id}>
            <TwitterTweetEmbed
              tweetId={id}
              options={{
                conversation: "none",
                cards: "none",
                lang: locale ?? "en",
              }}
            />
          </TweetContainer>
        ))}
      </Carousel>
    </ResponsiveWrapper>
  );
};

export default SectionArticleTweetList;
