import styled from "styled-components";
import { TemplateThemeConfiguration } from "../../../contentstack/content-types/pages/templates/default/typings";

const MOBILE_BREAKPOINT = 800;
export const Section = styled.div`
  margin-top: 4%;

  &.first-section {
    margin-top: 0;
  }

  @media (max-width: ${MOBILE_BREAKPOINT + "px"}) {
    margin-top: 30px;
  }
`;

interface WrapperProps {
  theme: TemplateThemeConfiguration;
  hasFooterVideo?: boolean;
}
export const StyledArticleWrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }) => theme.background_color};
  color: ${({ theme }) => theme.paragraph_font_color};
  overflow: hidden;
  ${({ theme }) => (theme.background_image ? `background-image: url("${theme.background_image.url}");` : "")}
  background-repeat: ${({ theme }) => (theme.background_image_repeats ? "repeat-y" : "no-repeat")};
  background-position: 50% 0;
  background-size: 100%;
  padding-bottom: ${({ hasFooterVideo }) => (hasFooterVideo ? "0" : "75px")};
  @media screen and (max-width: ${MOBILE_BREAKPOINT + "px"}) {
    ${({ theme }) =>
      theme.background_image_mobile ? `background-image: url("${theme.background_image_mobile.url}");` : ""}
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    ${({ theme }) =>
      theme?.font_options?.header_font?.font_family
        ? `font-family: ${theme.font_options.header_font.font_family};`
        : ""}
  }
  p,
  div,
  span {
    ${({ theme }) =>
      theme?.font_options?.body_font?.font_family ? `font-family: ${theme.font_options.body_font.font_family};` : ""}
  }
`;
