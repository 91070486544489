import styled, { keyframes } from "styled-components";
import { easing } from "../../../utils/style";
import {
  COLUMN_WIDTH,
  MEDIA_MOBILE_ALL,
  MOBILE_MARGIN,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_BACKGROUND_DARK,
} from "../../../layouts/default/theme";

import ComponentImage from "../../../components/image";
import { cssLocalizedLineHeight } from "../../../components/css";

export const CarouselWrapper = styled.section`
  box-sizing: border-box;
  width: 100%;
  max-width: ${COLUMN_WIDTH * 14}px;
  padding: 0 ${COLUMN_WIDTH}px;
  margin: 0 auto;

  @media ${MEDIA_MOBILE_ALL} {
    padding: 0 ${MOBILE_MARGIN}px;
  }
`;

export const Introduction = styled.div``;

export const Display = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${(10 / 16) * 100}%;
  margin-top: 35px;

  background: ${COLOR_BACKGROUND_DARK};
`;

export const DisplayHex = styled.div`
  display: block;
  @media ${MEDIA_MOBILE_ALL} {
    display: none;
  }

  &.is-mobile {
    display: none;
    @media ${MEDIA_MOBILE_ALL} {
      display: block;
    }
  }

  html.is-ms-10 & {
    visibility: hidden;
  }
`;

const displayItemAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const DisplayItem = styled.div`
  overflow: hidden;

  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  display: none;

  &.is-active {
    display: block;

    animation: ${displayItemAnimation} both 0.5s 0.3s ${easing.easeInOutCubic};
  }

  > * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Nav = styled.nav`
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0 -10px;
  padding: 30px 10px;
  border-bottom: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;

export const NavThumb = styled.button`
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0;

  position: relative;
  width: 110px;
  height: 70px;
  margin: 7px 10px;

  cursor: pointer;
`;

export const NavThumbHex = styled.div`
  opacity: 0;
  transition: opacity 0.2s ${easing.easeInOutCubic};
  z-index: 1;

  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;

  ${NavThumb}.is-active & {
    opacity: 1;
  }
`;

export const NavThumbImage = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  opacity: 0.5;
  transition: opacity 0.2s ${easing.easeInOutCubic};

  ${NavThumb}:hover & {
    opacity: 0.9;
  }

  ${NavThumb}.is-active & {
    opacity: 1;
  }

  > * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const CaptionWrapper = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;

  padding-bottom: 100px;
`;

export const CaptionItem = styled.li`
  display: none;

  &.is-active {
    display: block;
  }
`;

const captionAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

export const CaptionTitle = styled.h4`
  font-size: 18px;
  ${cssLocalizedLineHeight(22 / 18)}
  font-weight: bold;
  letter-spacing: 0.05em;

  animation: ${captionAnimation} both 0.9s 0s ${easing.easeOutQuart};
`;

export const CaptionDesc = styled.div`
  margin-top: 10px;

  font-size: 18px;
  ${cssLocalizedLineHeight(30 / 18)}
  letter-spacing: 0.04em;

  animation: ${captionAnimation} both 1.1s 0.07s ${easing.easeOutQuart};
`;
