import styled, { css } from "styled-components";
import ImageGridItem from "./components/image-grid-item";
import { BASE_WIDTH } from "../../../layouts/default/theme";

export const Wrapper = styled.section``;

export const ImageGridContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;

  .carousel {
    width: 100%;
  }
`;

export const ImageItem = styled(ImageGridItem)``;

interface Props {
  numImages: number;
}

export const ResponsiveWrapper = styled.div<Props>`
  margin: 0 auto;
  max-width: ${(props) => (props.numImages < 5 ? "962px" : "100%")};

  ${ImageItem} {
    ${({ numImages }) => {
      return numImages < 2
        ? css`
            max-width: 100%;
            box-sizing: border-box;
          `
        : null;
    }}

    width: ${(props) => (props.numImages < 2 ? "525px" : props.numImages < 4 ? "300px" : "220px")};
    padding-left: ${(props) => (props.numImages < 5 ? "15px" : "10px")};
    padding-right: ${(props) => (props.numImages < 5 ? "15px" : "10px")};

    &:first-of-type {
      padding-left: ${(props) => (props.numImages < 5 ? "30px" : "20px")};
      /* padding-left: 0; */
    }
    &:last-of-type {
      padding-right: ${(props) => (props.numImages < 5 ? "30px" : "20px")};
      /* padding-right: 0; */
    }
  }

  ${ImageGridContainer} {
    &:before {
      content: "";
      margin: auto;
    }

    &:after {
      content: "";
      margin: auto;
    }
  }

  @media (max-width: 1000px) {
    max-width: 100%;

    ${ImageGridContainer} {
      width: 100%;
    }
  }
`;
