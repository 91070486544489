import React, { useState, useEffect, useCallback } from "react";

import {
  Wrapper,
  GalleryWrapper,
  GalleryInner,
  GalleryFrame,
  GalleryGrid,
  GalleryGridList,
  GalleryGridItem,
  GalleryGridItemAnchor,
  GalleryGridItemImage,
  GalleryDisplay,
  GalleryDisplayItemImage,
  GalleryDock,
  GalleryDockInfo,
  GalleryDockInfoTitle,
  GalleryDockInfoCountTotal,
  GalleryDockInfoCountCurrent,
  GalleryDockControls,
  GalleryDockButton,
  GalleryDockButtonIcon,
  GalleryDockNavigation,
  GalleryDockNavigationButton,
  ArrowIcon,
  BottomHighlight,
} from "./style";

import HexOutline, { HexTransitionType, Controller as HexOutlineController } from "../../../components/hex-outline";
import { ReactComponent as ArrowLeftSvg } from "../../../assets/arrows/left.svg";
import { ReactComponent as ArrowRightSvg } from "../../../assets/arrows/right.svg";
import { Grid } from "../../../assets/symbols";

const ArrowLeft = ArrowIcon.withComponent(ArrowLeftSvg);
const ArrowRight = ArrowIcon.withComponent(ArrowRightSvg);
const GalleryDockButtonIconGrid = GalleryDockButtonIcon.withComponent(Grid);

// Temp
import { COLOR_PRIMARY_BLUE, COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";
import { ease, mod } from "../../../utils/math";
import SlideshowComponent from "../../../components/slideshow";
import { useIntl } from "gatsby-plugin-intl";

export interface GalleryImage {
  image: React.ReactElement;
  thumbnail?: React.ReactElement;
  caption?: string;
}

export interface SectionArticleGalleryProps {
  className?: string;
  startInZoomedMode?: boolean;
  title?: string;
  images: GalleryImage[];
}

const SectionArticleGallery: React.FC<SectionArticleGalleryProps> = ({
  className,
  startInZoomedMode = false,
  title,
  images,
}) => {
  const intl = useIntl();

  const [isZoomed, setIsZoomed] = useState(startInZoomedMode);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeSlideshowIndex, setActiveSlideshowIndex] = useState<number>();

  const [isPrevHovering, setIsPrevHovering] = useState(false);
  const [isNextHovering, setIsNextHovering] = useState(false);

  const [hexOutlineController, setHexOutlineController] = useState<HexOutlineController>();
  const onInitHexOutline = useCallback((controller) => setHexOutlineController(controller), []);

  // refresh hex outline when image caption changes
  useEffect(() => {
    if (!hexOutlineController) return;
    hexOutlineController.refresh();
  }, [isZoomed, activeImageIndex, hexOutlineController]);

  function selectGridItem(index: number) {
    setActiveImageIndex(index);
    setIsZoomed(true);
  }

  function navigateActiveImage(direction: number) {
    const newIndex = mod(activeImageIndex + direction, images.length);
    setActiveImageIndex(newIndex);
  }

  return (
    <React.Fragment>
      {activeSlideshowIndex !== undefined && (
        <SlideshowComponent
          index={activeSlideshowIndex}
          items={images.map((image) => {
            return {
              src: image.image.props.src,
              w: 0,
              h: 0,
              title: image.caption,
            };
          })}
          onClose={() => setActiveSlideshowIndex(undefined)}
        />
      )}
      <Wrapper className={`${className}`}>
        <GalleryWrapper className={`${isZoomed ? "is-zoomed" : ""}`}>
          <GalleryInner>
            <HexOutline
              isActive
              clipRightTop={25}
              transition={HexTransitionType.WIRE}
              strokeColor={COLOR_OUTLINE_LIGHT_GREY}
              accentColor={COLOR_OUTLINE_LIGHT_GREY}
              transitionDuration={1500}
              transitionDelay={0}
              onInit={onInitHexOutline}
            />

            <GalleryFrame>
              {!isZoomed && (
                <GalleryGrid>
                  <GalleryGridList>
                    {images.map((item, index) => {
                      return (
                        <GalleryGridItem key={index}>
                          <GalleryGridItemAnchor
                            aria-label={intl.formatMessage(
                              { id: "view-image-x-of-y.action.icu" },
                              { x: index + 1, y: images.length },
                            )}
                            onClick={() => selectGridItem(index)}
                          >
                            <GalleryGridItemImage>{item.thumbnail ? item.thumbnail : item.image}</GalleryGridItemImage>
                          </GalleryGridItemAnchor>
                        </GalleryGridItem>
                      );
                    })}
                  </GalleryGridList>
                </GalleryGrid>
              )}
              {isZoomed && (
                <GalleryDisplay>
                  <GalleryDisplayItemImage onClick={() => setActiveSlideshowIndex(activeImageIndex)}>
                    {images[activeImageIndex].image}
                  </GalleryDisplayItemImage>
                </GalleryDisplay>
              )}
            </GalleryFrame>

            <GalleryDock>
              <GalleryDockInfo>
                {!isZoomed && title && <GalleryDockInfoTitle>{title}</GalleryDockInfoTitle>}
                {isZoomed && (
                  <GalleryDockInfoTitle>{images[activeImageIndex].caption || <>&nbsp;</>}</GalleryDockInfoTitle>
                )}
                <GalleryDockInfoCountTotal>
                  {intl.formatMessage(
                    { id: "number-of-images.title.icu" },
                    {
                      images: images.length,
                    },
                  )}
                </GalleryDockInfoCountTotal>
                <GalleryDockInfoCountCurrent>
                  {intl.formatMessage(
                    { id: "x-of-y.title.icu" },
                    {
                      x: activeImageIndex + 1,
                      y: images.length,
                    },
                  )}
                </GalleryDockInfoCountCurrent>
              </GalleryDockInfo>
              <GalleryDockControls>
                <GalleryDockButton
                  aria-label={intl.formatMessage({ id: "thumbnail-view.action" })}
                  onClick={() => setIsZoomed(false)}
                >
                  <GalleryDockButtonIconGrid />
                </GalleryDockButton>
                <GalleryDockNavigation>
                  <GalleryDockNavigationButton
                    aria-label={intl.formatMessage({ id: "previous.action" })}
                    onClick={() => navigateActiveImage(-1)}
                    onMouseEnter={() => setIsPrevHovering(true)}
                    onMouseLeave={() => setIsPrevHovering(false)}
                  >
                    <HexOutline
                      isActive={isZoomed}
                      clipLeftTop={8}
                      strokeColor={COLOR_PRIMARY_BLUE}
                      interactive
                      hovering={isPrevHovering}
                      hoverDuration={300}
                      hoverEase={ease.outExpo}
                    />
                    <ArrowLeft />
                  </GalleryDockNavigationButton>
                  <GalleryDockNavigationButton
                    aria-label={intl.formatMessage({ id: "next.action" })}
                    onClick={() => navigateActiveImage(1)}
                    onMouseEnter={() => setIsNextHovering(true)}
                    onMouseLeave={() => setIsNextHovering(false)}
                  >
                    <HexOutline
                      isActive={isZoomed}
                      clipRightBot={8}
                      strokeColor={COLOR_PRIMARY_BLUE}
                      interactive
                      hovering={isNextHovering}
                      hoverDuration={150}
                      hoverEase={ease.inOutQuart}
                    />
                    <ArrowRight />
                  </GalleryDockNavigationButton>
                </GalleryDockNavigation>
              </GalleryDockControls>
            </GalleryDock>
            <BottomHighlight />
          </GalleryInner>
        </GalleryWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default SectionArticleGallery;
