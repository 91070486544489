import styled, { css } from "styled-components";

import HexOutlineComponent from "../../../components/hex-outline";
import { rem } from "../../../utils/style";
import { COLOR_OUTLINE_LIGHT_GREY, MEDIA_MOBILE_ALL, COLOR_PRIMARY_GOLD } from "../../../layouts/default/theme";
import LinkComponent from "../../../components/link";
import MetaComponent from "./meta";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedItalic,
  cssLocalizedUppercase,
} from "../../../components/css";

const BLUR_SIZE = 10;

export const Wrapper = styled.div``;

export const WrapperInner = styled.div`
  position: relative;
  padding: 0 20px;
`;

export const BackgroundImage = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  width: 100%;
  height: 66%;

  > * {
    width: calc(100% + ${BLUR_SIZE * 4}px);
    height: calc(100% + ${BLUR_SIZE * 4}px);
    margin: -${BLUR_SIZE * 2}px;
    filter: blur(${BLUR_SIZE}px);

    html.is-ms-edge & {
      filter: none;
    }

    html.is-ms-ie & {
      height: auto;
      min-height: 100%;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    html.is-ms-edge & {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
`;

export const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
`;

export const Overview = styled.div`
  position: relative;
  padding-top: 10px;
`;

export const Media = styled.div`
  position: relative;
  padding-top: ${Math.round((610 / 1100) * 10000) / 100 + "%"};
  margin: 0 -10px 0;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Details = styled.div`
  padding: 4%;
  text-align: center;
`;

export const Title = styled.div`
  ${cssLocalizedItalic}
  font-weight: 800;
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedUppercase}
`;

export const Blurb = styled.p`
  font-size: ${rem(14)};
  ${cssLocalizedLineHeight(18 / 14)}
  letter-spacing: 0.06em;
  margin-top: 5px;

  @media ${MEDIA_MOBILE_ALL} {
    ${cssLocalizedLineHeight(20 / 14)}
    letter-spacing: 0.04em;
  }
`;

export const MobileCategory = styled(LinkComponent)`
  display: none;
`;

export const StyledHexOutline = styled(HexOutlineComponent)``;

export const Meta = styled(MetaComponent)``;

const rowLayout = css`
  ${Title} {
    font-size: ${rem(40)};
  }

  ${Meta} {
    border: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
    margin-top: -1px;

    &.hex-outline-visible {
      /* once hex outline is visible, remove top border to avoid having it look thicker */
      border-top: 0;
      margin-top: 0;
    }
  }
`;

const columnLayout = css`
  ${StyledHexOutline}, ${BackgroundImage} {
    display: none;
  }

  ${WrapperInner} {
    padding: 0;
  }

  ${Content} {
    padding-top: 0;
  }

  ${Media} {
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 100%;
  }

  ${Overview} {
    padding: 0;
  }

  ${Details} {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 30px 20px;
  }

  ${Title}, ${Blurb}, ${MobileCategory} {
    text-align: left;
  }

  ${Title} {
    margin-bottom: 5px;
    font-size: ${rem(20)};
  }

  ${MobileCategory} {
    margin-bottom: 10px;
    display: inline-block;
    font-size: ${rem(10)};
    font-weight: 600;
    ${cssLocalizedUppercase}
    letter-spacing: 0.05em;
    color: ${COLOR_PRIMARY_GOLD};

    @media ${MEDIA_MOBILE_ALL} {
      ${cssLocalizedLineHeight(14 / 10)}
      letter-spacing: 0.1em;
    }
  }
`;

const BREAKPOINT = 800;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT + 1 + "px"}) {
    ${rowLayout}
  }

  @media (max-width: ${BREAKPOINT + "px"}) {
    ${columnLayout}
  }
`;
