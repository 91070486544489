import styled from "styled-components";
import { COLOR_OUTLINE_LIGHT_GREY } from "../../../layouts/default/theme";

export const Wrapper = styled.div`
  max-width: 860px;
  margin: 0 auto;
`;

export const SolidDivider = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  top: 0px;
`;

export const SolidDividerLine = styled.div`
  height: 18px;
  border-top: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;
