import styled from "styled-components";
import { MEDIA_MOBILE_ALL } from "../../../layouts/default/theme";

export const ResponsiveWrapper = styled.section`
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${MEDIA_MOBILE_ALL} {
    padding: 0 10px;
  }

  .carousel {
    width: 100%;
  }

  html.is-ms-ie10 & {
    /* Embedded Tweets are not supported by Twitter on <= IE 10, so hide the entire section */
    display: none;
  }
`;

export const TweetContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 280px;
  padding: 10px;
`;
