import styled from "styled-components";

export const Section = styled.div`
  position: relative;

  width: 100%;
  height: 100vh;
  min-height: 400px;
  max-height: 1200px;
`;

export const VideoContainer = styled.div`
  overflow: hidden;

  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Video = styled.video`
  opacity: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;
