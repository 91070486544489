import React, { useRef, useEffect, useState, useCallback, useContext } from "react";

import {
  YouTubeContainer,
  YouTubeContainerInner,
  YouTube,
  YouTubeHexOutline,
  SideHighlight,
  ResponsiveWrapper,
} from "./style";

import { useInView } from "../../../utils/hooks";
import RiotLocaleContext from "../../../contexts/riot-locale";

export interface SectionArticleYouTubeProps {
  videoId: string;
  autoplay?: boolean;
  locale?: string;
  className?: string;
}

const SectionArticleYouTube: React.FC<SectionArticleYouTubeProps> = ({
  videoId,
  autoplay = false,
  locale,
  className,
}) => {
  const localeContext = useContext(RiotLocaleContext);
  if (locale === undefined) {
    locale = localeContext;
  }
  const [paused, setPaused] = useState<boolean>(true);
  const [pausedInView, setPausedInView] = useState<boolean>(false);
  const [player, setPlayer] = useState<YT.Player | null>(null);
  const videoRef = useRef<HTMLDivElement>(null);
  const [inViewVideoRef, inViewVideo] = useInView();

  // play/pause video when in view
  useEffect(() => {
    // user manually paused video when in view, so don't restart it
    if (!autoplay || pausedInView) return;

    // start playing video when in view
    setPaused(!inViewVideo);
    if (player) player.pauseVideo();
  }, [player, inViewVideo]);

  // keep track when user manually paused video
  useEffect(() => {
    if (!autoplay) return;

    if (paused) {
      if (inViewVideo) {
        // paused by user while watching video
        setPausedInView(true);
      } else {
        // this was paused due to video coming out of view
      }
    } else {
      // started to play, so reset state
      setPausedInView(false);
    }
  }, [paused]);

  // ensure video is playing when unpaused
  useEffect(() => {
    if (!player) return;
    const p = player;

    // try playing video as soon as unpaused
    if (!paused) p.playVideo();
  }, [player, paused]);

  // memoized callbacks
  const onReadyVideo = useCallback<YT.PlayerEventHandler<YT.PlayerEvent>>((event) => setPlayer(event.target), []);
  const onPauseVideo = useCallback(() => setPaused(true), []);
  const onPlayingVideo = useCallback(() => setPaused(false), []);

  return (
    <ResponsiveWrapper className={className}>
      <YouTubeContainer ref={inViewVideoRef}>
        <YouTubeContainerInner ref={videoRef}>
          <YouTube
            playsInline={true}
            video={videoId}
            autoplay={autoplay}
            width="100%"
            height="100%"
            muted={autoplay /* We only want to mute video on autoplay */}
            onReady={onReadyVideo}
            onPause={onPauseVideo}
            onPlaying={onPlayingVideo}
            lang={locale}
            allowFullscreen={true}
            annotations={false}
            modestBranding={true}
            showRelatedVideos={false}
          />
          <SideHighlight />
          <YouTubeHexOutline isActive clipRightTop={20} />
        </YouTubeContainerInner>
      </YouTubeContainer>
    </ResponsiveWrapper>
  );
};

export default SectionArticleYouTube;
