import styled, { css, keyframes } from "styled-components";
import { COLOR_BACKGROUND_WHITE, COLOR_TEXT_LIGHT_GREY, COLOR_TEXT_DARK_GREY } from "../../../layouts/default/theme";
import { cssLocalizedUppercase } from "../../../components/css";

const CONTROL_ICON_BLUE = "#29C2DF";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  animation: ${fadeIn} 1s linear;
  max-width: 860px;
  margin: 0 auto;

  @media (max-width: 900px) {
    padding: 20px;
  }
`;

export const AudioPlayerContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: -6px;
`;

const PlayerControlSvgColors = css`
  cursor: pointer;

  .st0 {
    fill: ${CONTROL_ICON_BLUE};
    stroke: ${CONTROL_ICON_BLUE};
  }
  .st1 {
    fill: ${COLOR_BACKGROUND_WHITE};
    stroke: ${COLOR_BACKGROUND_WHITE};
  }
`;

export const PlaybackIcon = styled.svg<{ "data-visible": boolean }>`
  display: ${(props) => (props["data-visible"] ? "block" : "none")};
  width: 40px;
  height: 40px;
  ${PlayerControlSvgColors}
`;

export const TimelineSlider = styled.div`
  flex-grow: 1;
  min-width: 150px;
  margin: 19px 12px;
  overflow: auto;

  .rc-slider {
    overflow: hidden;
  }

  .rc-slider-track {
    position: relative;
    bottom: 12px;
    background-color: ${COLOR_TEXT_DARK_GREY};
    height: 3px;
    cursor: pointer;
  }

  .rc-slider-handle {
    position: relative;
    height: 8px;
    width: 8px;
    top: -11px;
    cursor: pointer;
  }

  .rc-slider-rail {
    position: relative;
    top: -9px;
    height: 10px;
    background-color: ${COLOR_BACKGROUND_WHITE};
    border-bottom: 1px solid ${COLOR_TEXT_LIGHT_GREY};
    cursor: pointer;
  }
`;

export const SoundIcon = styled.svg<{ "data-visible": boolean }>`
  display: ${(props) => (props["data-visible"] ? "block" : "none")};
  position: relative;
  top: 12px;
  width: 20px;
  height: 10px;
  ${PlayerControlSvgColors}
`;

export const TimeDisplay = styled.div`
  width: 28px;
  position: relative;
  top: 12px;
  font-size: 10px;
  text-align: left;
  padding-left: 12px;
  color: ${COLOR_TEXT_LIGHT_GREY};
  letter-spacing: 0.04em;
`;

export const Title = styled.div`
  position: relative;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  ${cssLocalizedUppercase}
  letter-spacing: 0.1em;
  color: ${COLOR_TEXT_DARK_GREY};
`;
