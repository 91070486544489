import React, { useState, useRef, useEffect } from "react";
import Slider from "rc-slider";

import { ReactComponent as SvgPlayIcon } from "../../../../lib/assets/audio/AudioPlayIcon.svg";
import { ReactComponent as SvgPauseIcon } from "../../../../lib/assets/audio/AudioPauseIcon.svg";
import { ReactComponent as SvgSoundOnIcon } from "../../../../lib/assets/audio/AudioSoundOnIcon.svg";
import { ReactComponent as SvgSoundOffIcon } from "../../../../lib/assets/audio/AudioSoundOffIcon.svg";

import {
  Wrapper,
  AudioPlayerContainer,
  ControlsContainer,
  PlaybackIcon,
  TimelineSlider,
  SoundIcon,
  TimeDisplay,
  Title,
} from "./style";
import { useIntl } from "gatsby-plugin-intl";

export interface SectionArticleAudioProps {
  src: string;
  title?: string;
  className?: string;
}

const SectionArticleAudio: React.FC<SectionArticleAudioProps> = ({ src, title, className }) => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [time, setTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState("");
  const [canPlay, setCanPlay] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const SLIDER_RANGE_MAX = 10000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (audioRef && audioRef.current) {
        const secondsRemaining = audioRef.current.duration - audioRef.current.currentTime;
        setRemainingTime(formatDuration(secondsRemaining));
        setTime(Math.floor((audioRef.current.currentTime / audioRef.current.duration) * SLIDER_RANGE_MAX));
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [audioRef, audioRef.current]);

  const formatDuration = (dur: number) => {
    if (!dur) return "";

    const dateObj = new Date(dur * 1000);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();
    const formattedTime = `${formatTime(minutes)}:${formatTime(seconds)}`;

    return hours ? `${formatTime(hours)}:${formattedTime}` : formattedTime;
  };

  const formatTime = (span: number) => span.toString().padStart(2, "0");

  const playOrPause = () => {
    setPlaying(!playing);
    if (audioRef && audioRef.current) {
      if (!playing) audioRef.current.play();
      else audioRef.current.pause();
    }
  };

  const muteOrUnmute = () => {
    setMuted(!muted);
    if (audioRef && audioRef.current) {
      audioRef.current.muted = !muted;
    }
  };

  const handleSliderChange = (newPosition: number) => {
    setTime(newPosition);
    if (audioRef && audioRef.current) {
      const newTime = (newPosition / SLIDER_RANGE_MAX) * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
    }
  };

  const intl = useIntl();

  return (
    <Wrapper className={className}>
      <AudioPlayerContainer>
        <ControlsContainer>
          <PlaybackIcon
            as={SvgPauseIcon}
            data-visible={playing}
            onClick={playOrPause}
            aria-label={intl.formatMessage({ id: "playback.pause.action" })}
          />
          <PlaybackIcon
            as={SvgPlayIcon}
            data-visible={!playing}
            onClick={playOrPause}
            aria-label={intl.formatMessage({ id: "playback.play.action" })}
          />
          <TimelineSlider>
            <Slider onChange={handleSliderChange} min={0} max={SLIDER_RANGE_MAX} value={time} defaultValue={0} />
          </TimelineSlider>
          <SoundIcon
            as={SvgSoundOffIcon}
            data-visible={muted}
            onClick={muteOrUnmute}
            aria-label={intl.formatMessage({ id: "playback.unmute.action" })}
          />
          <SoundIcon
            as={SvgSoundOnIcon}
            data-visible={!muted}
            onClick={muteOrUnmute}
            aria-label={intl.formatMessage({ id: "playback.mute.action" })}
          />
          <TimeDisplay
            aria-label={intl
              .formatMessage({ id: "playback.time-remaining-with-time.title" })
              .replace("%time%", remainingTime)}
          >
            {canPlay ? remainingTime : " " /* Need a space here to avoid UI reflow on show */}
          </TimeDisplay>
        </ControlsContainer>
        {title && <Title>{title}</Title>}
      </AudioPlayerContainer>
      <audio preload="metadata" ref={audioRef} onCanPlay={() => setCanPlay(true)}>
        <source src={src} type="audio/mpeg" />
      </audio>
    </Wrapper>
  );
};

export default SectionArticleAudio;
