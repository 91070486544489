import React, { useRef, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUiDefault from "photoswipe/dist/photoswipe-ui-default";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import { Wrapper, GalleryDockNavigationButton, Separator, ArrowIcon, CloseIcon } from "./style";
import FreezeScrollingElementContext from "../../contexts/freeze-scrolling-element";
import { HexOutline } from "../../sections/category/article-list/navigation/style";
import { COLOR_PRIMARY_BLUE } from "../../layouts/default/theme";
import { ease } from "../../utils/math";
import { ReactComponent as ArrowLeftSvg } from "../../assets/arrows/left.svg";
import { ReactComponent as ArrowRightSvg } from "../../assets/arrows/right.svg";
import { ReactComponent as CloseSvg } from "./assets/close.svg";
import { useIntl } from "gatsby-plugin-intl";

const ArrowLeft = ArrowIcon.withComponent(ArrowLeftSvg);
const ArrowRight = ArrowIcon.withComponent(ArrowRightSvg);
const Close = CloseIcon.withComponent(CloseSvg);

export interface SlideshowComponentProps {
  items: Array<
    PhotoSwipe.Item & {
      title?: string;
    }
  >;
  index?: number;
  onClose?: () => void;
}

const SlideshowComponent: React.FC<SlideshowComponentProps> = ({ items, index = 0, onClose }) => {
  const intl = useIntl();

  const pswpRef = useRef<HTMLDivElement | null>(null);
  const photoSwipeRef = useRef<PhotoSwipe<PhotoSwipeUiDefault.Options>>();
  const freezeScrollingElement = useContext(FreezeScrollingElementContext);

  // initialize photoSwipe instance
  useEffect(() => {
    if (!pswpRef.current) return;

    const photoSwipe = new PhotoSwipe(pswpRef.current, PhotoSwipeUiDefault, items, {
      index,
      showHideOpacity: true,
      history: false,
      showAnimationDuration: 0,
      hideAnimationDuration: 0,
      shareEl: false,
    });

    let closed = false;

    // notify on close
    photoSwipe.listen("close", () => {
      // allow main content to scroll again
      freezeScrollingElement(false);

      closed = true;
      if (onClose) onClose();
    });

    // WORKAROUND: detect image dimensions when missing
    // @see https://github.com/dimsemenov/PhotoSwipe/issues/796#issuecomment-269765635
    photoSwipe.listen("gettingData", (i, item) => {
      if (!item.w || !item.h) return;
      if (!item.src) return;
      if (item.w < 1 || item.h < 1) {
        const img = new Image();
        img.addEventListener("load", () => {
          if (closed) return;
          item.w = img.width;
          item.h = img.height;
          photoSwipe.updateSize(true);
        });
        img.src = item.src;
      }
    });

    // prevent main content from scrolling
    freezeScrollingElement(true);

    // open slideshow
    photoSwipe.init();
    (photoSwipe as any).ui.updateIndexIndicator = () => {
      if (!pswpRef.current) return;

      const counterEl = pswpRef.current.querySelector(".pswp__counter");
      if (!counterEl) return;
      // TODO: Fix this intl formatting issue.
      counterEl.textContent = intl.formatMessage(
        { id: "x-of-y.title.icu" },
        {
          x: photoSwipe.getCurrentIndex() + 1,
          y: photoSwipe.items.length,
        },
      );
    };

    photoSwipeRef.current = photoSwipe;

    return () => {
      // clean up
      if (!closed) photoSwipe.close();
      photoSwipeRef.current = undefined;
    };
  }, [pswpRef.current]);

  // SSR render not supported
  if (typeof window === "undefined") return null;

  return ReactDOM.createPortal(
    /* <!-- Root element of PhotoSwipe. Must have class pswp. --> */
    <Wrapper ref={pswpRef} className="pswp" tabIndex={-1} role="dialog" aria-hidden="true">
      {/* <!-- Background of PhotoSwipe.
            It's a separate element as animating opacity is faster than rgba(). --> */}
      <div className="pswp__bg"></div>

      {/* <!-- Slides wrapper with overflow:hidden. --> */}
      <div className="pswp__scroll-wrap">
        {/* <!-- Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. --> */}
        <div className="pswp__container">
          <div className="pswp__item"></div>
          <div className="pswp__item"></div>
          <div className="pswp__item"></div>
        </div>

        {/* <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. --> */}
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            {/* <!--  Controls are self-explanatory. Order can be changed. --> */}

            <div className="pswp__counter"></div>

            <button className="pswp__button pswp__button--close" title={intl.formatMessage({ id: "close.action" })}>
              <Close
                onClick={() => {
                  if (!photoSwipeRef.current) return;
                  photoSwipeRef.current.close();
                }}
              />
            </button>

            <Separator />

            {/* <button
              className="pswp__button pswp__button--share"
              title={intl.formatMessage({id: "share.title" })}
            ></button> */}

            {/* <button
              className="pswp__button pswp__button--fs"
              title={intl.formatMessage({id: "full-screen.action" })}
            ></button> */}

            <button
              className="pswp__button pswp__button--next"
              aria-label={intl.formatMessage({ id: "next.action" })}
              onClick={() => {
                if (!photoSwipeRef.current) return;
                photoSwipeRef.current.next();
              }}
            >
              <GalleryDockNavigationButton>
                <HexOutline
                  isActive={true}
                  clipRightBot={8}
                  strokeColor={COLOR_PRIMARY_BLUE}
                  hoverDuration={150}
                  hoverEase={ease.inOutQuart}
                  transitionDelay={300}
                  onInit={(c) => setTimeout(() => c.refresh(), 0)}
                />
                <ArrowRight />
              </GalleryDockNavigationButton>
            </button>

            <button
              className="pswp__button pswp__button--previous"
              aria-label={intl.formatMessage({ id: "previous.action" })}
              onClick={() => {
                if (!photoSwipeRef.current) return;
                photoSwipeRef.current.prev();
              }}
            >
              <GalleryDockNavigationButton>
                <HexOutline
                  isActive={true}
                  clipLeftTop={8}
                  strokeColor={COLOR_PRIMARY_BLUE}
                  hoverDuration={300}
                  hoverEase={ease.outExpo}
                  transitionDelay={100}
                  onInit={(c) => setTimeout(() => c.refresh(), 0)}
                />
                <ArrowLeft />
              </GalleryDockNavigationButton>
            </button>

            {/* <button
              className="pswp__button pswp__button--zoom"
              title="Zoom in/out"
            ></button> */}

            {/* <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running --> */}
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip"></div>
          </div>

          {/* <button
            className="pswp__button pswp__button--arrow--left"
            title="Previous"
          ></button>

          <button
            className="pswp__button pswp__button--arrow--right"
            title="Next"
          ></button> */}

          <div className="pswp__caption">
            <div className="pswp__caption__center"></div>
          </div>
        </div>
      </div>
    </Wrapper>,
    window.document.body,
  );
};

export default SlideshowComponent;
