import styled from "styled-components";

const BREAKPOINT = 900;
export const ResponsiveWrapper = styled.div`
  display: flex;
  max-width: 860px;
  margin: 40px auto;

  @media (min-width: ${BREAKPOINT}px) {
    max-width: 860px;
  }

  @media (max-width: ${BREAKPOINT - 1}px) {
    padding: 0 20px;
  }
`;
