import React, { useEffect, useState } from "react";

import SectionArticleAccordion from "../../sections/article/accordion";
import SectionArticleAudio from "../../sections/article/audio";
import SectionArticleAuthorList from "../../sections/article/author-list";
import SectionArticleButtonList from "../../sections/article/button-list";
import SectionArticleCallOut from "../../sections/article/call-out";
import SectionArticleGallery from "../../sections/article/gallery";
import SectionArticleHeader from "../../sections/article/header";
import SectionArticleHtml from "../../sections/article/html";
import SectionArticleHtmlMediaSplit from "../../sections/article/html-media-split";
import SectionArticleRelatedArticles from "../../sections/article/related-articles";
import SectionArticleSeparator from "../../sections/article/separator";
import SectionArticleShare from "../../sections/article/share";
import SectionArticleTabList from "../../sections/article/tab-list";
import SectionArticleTweetList from "../../sections/article/tweet-list";
import SectionArticleImageGrid from "../../sections/article/image-grid";
import SectionArticleFullWidthImage from "../../sections/article/full-width-image";
import SectionArticlePromo from "../../sections/promo";
import SectionArticleYouTube from "../../sections/article/youtube";
import SectionArticleHTML5Video from "../../sections/article/html5-video";
import SectionEventHeader from "../../sections/event/header";
import SectionEventCarousel from "../../sections/event/carousel";
import SectionEventFooter from "../../sections/event/footer";
import SectionPatchNotesBody from "../../sections/patch-notes/body";
import SectionPatchNotesHeader from "../../sections/patch-notes/header";
import { ContentstackEmailForm } from "@riotgames/wwpub-components";
import SectionArticleTags from "../../sections/article/tags";

import { Section, StyledArticleWrapper } from "./style";
import { SectionType, Section as TemplateSection } from "./typings";
import { graphql } from "gatsby";

const SECTION_MAP: { [type in SectionType]: any } = {
  [SectionType.ARTICLE_ACCORDION]: SectionArticleAccordion,
  [SectionType.ARTICLE_AUDIO]: SectionArticleAudio,
  [SectionType.ARTICLE_AUTHOR_LIST]: SectionArticleAuthorList,
  [SectionType.ARTICLE_BUTTON_LIST]: SectionArticleButtonList,
  [SectionType.ARTICLE_GALLERY]: SectionArticleGallery,
  [SectionType.ARTICLE_HEADER]: SectionArticleHeader,
  [SectionType.ARTICLE_HTML]: SectionArticleHtml,
  [SectionType.ARTICLE_HTML_MEDIA_SPLIT]: SectionArticleHtmlMediaSplit,
  [SectionType.ARTICLE_RELATED_ARTICLES]: SectionArticleRelatedArticles,
  [SectionType.ARTICLE_CALL_OUT]: SectionArticleCallOut,
  [SectionType.ARTICLE_SEPARATOR]: SectionArticleSeparator,
  [SectionType.ARTICLE_SHARE]: SectionArticleShare,
  [SectionType.ARTICLE_TAB_LIST]: SectionArticleTabList,
  [SectionType.ARTICLE_TWEET_LIST]: SectionArticleTweetList,
  [SectionType.ARTICLE_IMAGE_GRID]: SectionArticleImageGrid,
  [SectionType.ARTICLE_FULL_WIDTH_IMAGE]: SectionArticleFullWidthImage,
  [SectionType.ARTICLE_PROMO]: SectionArticlePromo,
  [SectionType.ARTICLE_YOUTUBE]: SectionArticleYouTube,
  [SectionType.ARTICLE_HTML5VIDEO]: SectionArticleHTML5Video,
  [SectionType.EVENT_HEADER]: SectionEventHeader,
  [SectionType.EVENT_CAROUSEL]: SectionEventCarousel,
  [SectionType.EVENT_FOOTER]: SectionEventFooter,
  [SectionType.PATCH_NOTES_BODY]: SectionPatchNotesBody,
  [SectionType.PATCH_NOTES_HEADER]: SectionPatchNotesHeader,
  [SectionType.EMAIL_FORM]: ContentstackEmailForm,
  [SectionType.ARTICLE_TAGS]: SectionArticleTags,
};

interface Props {
  sections: TemplateSection[];
}

const Template: React.FC<Props> = ({ sections }) => {
  const [articleHasFooterVideo, setArticleHasFooterVideo] = useState<boolean>(false);
  useEffect(() => {
    const articleFooter = sections.filter((section) => section.type === "event_footer");
    if (
      articleFooter &&
      articleFooter[0] &&
      articleFooter[0].props &&
      // TODO: Find the way to make ts not complain when accessing this prop
      // @ts-ignore
      articleFooter[0].props.footer_video_enabled === true
    ) {
      setArticleHasFooterVideo(true);
    }
  }, []);
  return (
    <StyledArticleWrapper hasFooterVideo={articleHasFooterVideo}>
      {sections.map(({ type, key, props }, sectionIndex) => {
        const CurrentSection = SECTION_MAP[type];
        if (!CurrentSection) {
          console.warn(`Unsupported section type. (type: ${type})`);
          return;
        }

        const StyledCurrentSection = Section.withComponent(CurrentSection);

        const classNames = [`type-${type}`];
        if (sectionIndex === 0) classNames.push("first-section");
        if (sectionIndex === sections.length - 1) {
          classNames.push("last-section");
        }

        return <StyledCurrentSection className={classNames.join(" ")} key={key} {...props} />;
      })}
    </StyledArticleWrapper>
  );
};

export default Template;

export const ContentstackArticleExcerptFragment = graphql`
  fragment ContentstackArticleExcerpt on Contentstack_articles {
    uid
    title
    date
    description
    article_type
    url {
      url
    }
    external_link
    youtube_link
    banner {
      url
      uid
    }
    category {
      title
    }
    author {
      title
    }
    article_tags {
      title
      machine_name
      is_hidden
      url {
        url
      }
    }
  }
`;
