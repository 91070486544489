import React, { useState } from "react";
import { useResize } from "../../../utils/hooks";
import { ImageGridItemProps } from "./components/image-grid-item";
import Carousel from "../../../components/carousel";

import { Wrapper, ResponsiveWrapper, ImageGridContainer, ImageItem } from "./style";

export interface SectionArticleImageGridProps {
  images: ImageGridItemProps[];
  className?: string;
}

const ImageGrid: React.FC<SectionArticleImageGridProps> = ({ images, className }) => {
  const wrapperWidth = images.length < 5 ? { width: "960px" } : { width: "100%" };

  return (
    <Wrapper>
      <ResponsiveWrapper className={className} style={wrapperWidth} numImages={images.length}>
        <ImageGridContainer>
          <Carousel className={"carousel"}>
            {images.map((img, ind) => (
              <ImageItem
                key={ind}
                image={img.image}
                title={img.title}
                caption={img.caption}
                value={img.value}
                external_link={img.external_link}
                open_in_new_window={img.open_in_new_window}
              />
            ))}
          </Carousel>
        </ImageGridContainer>
      </ResponsiveWrapper>
    </Wrapper>
  );
};

export default ImageGrid;
