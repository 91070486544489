import React from "react";
import { SectionEventCarouselProps } from "../.";
import {
  CarouselItemType,
  CarouselItemBase,
  CarouselItemImage,
  CarouselItemHtml5Video,
  CarouselItemYouTubeVideo,
} from "../typings";
import richTextToReactElement from "../../../../../contentstack/utils/rich-text-to-react-element";
import { RichTextFieldType } from "../../../../../contentstack/fields/rich-text";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import { VideoData } from "../../../../../contentstack/content-types/html5-videos/transforms/typings";
import ComponentImage from "../../../../components/image";
import { videoDataSourcesToVideoSources } from "../../../../../contentstack/content-types/html5-videos/transforms/video-data-to-video-component";
import notEmpty from "../../../../../contentstack/utils/not-empty";

export interface SectionEvenCarouselContentstackProps {
  introduction?: RichTextFieldType;
  items: ContentstackCarouselItem[];
}

export const transformContentstackPropsToSectionProps = ({
  introduction,
  items,
  ...otherProps
}: SectionEvenCarouselContentstackProps): SectionEventCarouselProps => {
  return {
    ...otherProps,
    introduction: introduction ? richTextToReactElement(introduction) : undefined,
    items: items
      .map((item) => {
        const baseProps: CarouselItemBase = {
          type: item.type,
          title: item.title,
          description: item.description ? richTextToReactElement(item.description) : undefined,
          thumbnail: <ComponentImage src={item.thumbnailUrl} alt={item.title} objectFit="cover" />,
        };

        let transformedItem;

        switch (item.type) {
          case CarouselItemType.IMAGE:
            const imageItem: CarouselItemImage = {
              ...baseProps,
              type: item.type,
              image: <ComponentImage src={item.imageUrl || item.thumbnailUrl} alt={item.title} objectFit="cover" />,
            };
            transformedItem = imageItem;
            break;

          case CarouselItemType.HTML5_VIDEO:
            const html5VideoItem: CarouselItemHtml5Video = {
              ...baseProps,
              type: item.type,
              videoPoster: item.video.posterUrl ? (
                <ComponentImage src={item.video.posterUrl} alt="" objectFit="cover" />
              ) : undefined,
              videoSources: videoDataSourcesToVideoSources(item.video.sources),
            };
            transformedItem = html5VideoItem;
            break;

          case CarouselItemType.YOUTUBE_VIDEO:
            const youtubeVideoItem: CarouselItemYouTubeVideo = {
              ...baseProps,
              type: item.type,
              youtubeVideoId: item.youtubeVideoId,
            };
            transformedItem = youtubeVideoItem;
            break;
        }

        return transformedItem;
      })
      .filter(notEmpty),
  };
};

export interface ContentstackCarouselItemBase extends Pick<CarouselItemBase, "type" | "title"> {
  thumbnailUrl: ImageAssetUrl;
  description?: RichTextFieldType;
}

export interface ContentstackCarouselItemImage extends ContentstackCarouselItemBase, Pick<CarouselItemImage, "type"> {
  type: CarouselItemType.IMAGE;
  imageUrl: ImageAssetUrl;
}

export interface ContentstackCarouselItemHtml5Video
  extends ContentstackCarouselItemBase,
    Pick<CarouselItemHtml5Video, "type"> {
  type: CarouselItemType.HTML5_VIDEO;
  video: VideoData;
}

export interface ContentstackCarouselItemYouTubeVideo
  extends ContentstackCarouselItemBase,
    Pick<CarouselItemYouTubeVideo, "type" | "youtubeVideoId"> {
  type: CarouselItemType.YOUTUBE_VIDEO;
}

export type ContentstackCarouselItem =
  | ContentstackCarouselItemImage
  | ContentstackCarouselItemHtml5Video
  | ContentstackCarouselItemYouTubeVideo;
