import React from "react";
import { SectionArticleCallOutProps } from "../.";
import { RichTextFieldType } from "../../../../../contentstack/fields/rich-text";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import richTextToReactElement from "../../../../../contentstack/utils/rich-text-to-react-element";
import Image from "../../../../components/image";

export interface SectionArticleCallOutContentstackProps {
  image?: ImageAssetUrl;
  body: RichTextFieldType;
  external_link?: string;
  open_in_new_window?: boolean;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleCallOutContentstackProps,
): SectionArticleCallOutProps => {
  return {
    image: props.image ? <Image src={props.image} /> : undefined,
    body: richTextToReactElement(props.body),
    external_link: props.external_link,
    open_in_new_window: props.open_in_new_window,
  };
};
