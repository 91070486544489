import React from "react";

import { SectionArticleAuthorListProps } from "../typings";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";

export interface SectionArticleAuthorListContentstackProps {
  authorList: Array<{
    avatar?: ImageAssetUrl;
    name: string;
    fullName?: string;
    title?: string;
    bio?: string;
  }>;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleAuthorListContentstackProps,
): SectionArticleAuthorListProps => {
  return {
    authorList: props.authorList.map(({ avatar, ...authorProps }) => {
      return {
        avatar: avatar ? <ComponentImage src={avatar} objectFit={"cover"} /> : undefined,
        ...authorProps,
      };
    }),
  };
};
