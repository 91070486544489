import styled from "styled-components";
import ListComponent, { cssLayoutFn as cssListLayoutFn } from "../../category/article-list/list";
import backgroundPatternReverse from "./assets/bg-pattern-reverse.png";
import { COLOR_BACKGROUND_LIGHT_GREY } from "../../../layouts/default/theme";

export const Wrapper = styled.div`
  position: relative;

  padding: 50px 0;

  background: url(${backgroundPatternReverse}) repeat-x left bottom ${COLOR_BACKGROUND_LIGHT_GREY};
`;

export const ListWrapper = styled.div`
  width: 100%;
`;

export const List = styled(ListComponent)`
  max-width: 1200px;
  margin: 0 auto;
`;

const BREAKPOINT_DESKTOP = 1000;
const BREAKPOINT_TABLET = 600;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT_DESKTOP + 1 + "px"}) {
    ${cssListLayoutFn({
      columns: 3,
    })}
  }

  @media (max-width: ${BREAKPOINT_DESKTOP + "px"}) {
    ${cssListLayoutFn({
      columns: 2,
    })}
  }

  @media (max-width: ${BREAKPOINT_TABLET + "px"}) {
    ${cssListLayoutFn({
      columns: 1,
    })}
`;
