import styled, { css } from "styled-components";
import { Order } from ".";
import { COLUMN_WIDTH, MOBILE_MARGIN } from "../../../layouts/default/theme";

export const Wrapper = styled.div`
  max-width: 860px;
  margin: 0 auto;
`;

export const WrapperInner = styled.div`
  display: flex;
`;

export const Media = styled.div`
  > * {
    width: 100%;
  }
`;

export const Html = styled.div`
  text-align: left;
`;

const rowLayout = css`
  ${Media},
  ${Html} {
    flex: 1 1 0%;
  }
`;

const columnLayout = css`
  ${WrapperInner} {
    flex-direction: column;
  }
`;

const BREAKPOINT = 800;
const MARGIN_BREAKPOINT = 900;
export const ResponsiveWrapper = styled(Wrapper)<{ cssOrder: Order }>`
  @media (min-width: ${BREAKPOINT + 1 + "px"}) {
    ${rowLayout}

    ${Media} {
      order: ${({ cssOrder }) => (cssOrder === "html-first" ? 2 : undefined)};
    }

    ${Html} {
      padding: ${({ cssOrder }) => (cssOrder === "html-first" ? "0 70px 0 0" : "0 0 0 70px")};
      text-align: ${({ cssOrder }) => (cssOrder === "html-first" ? "left" : "right")};
    }
  }

  @media (max-width: ${BREAKPOINT + "px"}) {
    ${columnLayout}
  }

  @media (max-width: ${MARGIN_BREAKPOINT + "px"}) {
    padding: 0 ${MOBILE_MARGIN + "px"};
  }
`;
