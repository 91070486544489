import React from "react";
import { SectionArticleTabListProps } from "../.";
import { RichTextFieldType } from "../../../../../contentstack/fields/rich-text";
import richTextToReactElement from "../../../../../contentstack/utils/rich-text-to-react-element";

export interface SectionArticleTabListContentstackProps {
  tabs: Array<{
    title: string;
    body: RichTextFieldType;
  }>;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleTabListContentstackProps,
): SectionArticleTabListProps => {
  return {
    tabs: props.tabs.map(({ body, ...otherProps }) => {
      return {
        body: richTextToReactElement(body),
        ...otherProps,
      };
    }),
  };
};
