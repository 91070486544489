import React from "react";

import placeholder from "../../assets/placeholder.jpg";

import { AuthorProps } from "./typings";
import {
  Wrapper,
  StyledHexOutline,
  AvatarContainer,
  Ring,
  Avatar,
  Name,
  TitleAndAuthor,
  Bio,
  Underline,
} from "./style";

import { COLOR_OUTLINE_LIGHT_GREY } from "./../../../../../../lib/layouts/default/theme";
import ComponentImage from "../../../../../components/image";

interface Props extends AuthorProps {
  inView?: boolean;
  transitionDelay?: number;
}

const Author: React.FC<Props> = ({
  avatar,
  name,
  fullName,
  title,
  bio,
  inView = true,
  transitionDelay = 500,
  className,
}) => {
  // combine full name and title into a single string
  const fullNameAndTitle = `${title || ""}${(fullName && title && " ― ") || ""}${fullName || ""}`;

  // use default avatar image if one is not specified
  avatar = avatar || <ComponentImage src={placeholder} objectFit={"cover"} />;

  return (
    <Wrapper className={className}>
      <Underline />
      <AvatarContainer>
        <Avatar>{avatar}</Avatar>
        <Ring />
      </AvatarContainer>
      <Name>{name}</Name>
      {fullNameAndTitle && <TitleAndAuthor>{fullNameAndTitle}</TitleAndAuthor>}
      {bio && <Bio>{bio}</Bio>}
      <StyledHexOutline
        isActive={inView}
        transitionDelay={transitionDelay}
        clipRightTop={20}
        strokeColor={COLOR_OUTLINE_LIGHT_GREY}
      />
    </Wrapper>
  );
};

export default Author;
