import React from "react";
import { SectionArticleHeaderProps } from "../.";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import { DateFieldType } from "../../../../../contentstack/fields/date";
import ComponentImage from "../../../../components/image";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionArticleHeaderContentstackProps
  extends Pick<SectionArticleHeaderProps, "aspectRatio" | "title" | "blurb" | "category"> {
  url: UrlFieldType;
  backgroundImageUrl?: ImageAssetUrl;
  imageUrl?: ImageAssetUrl;
  date: DateFieldType;
  authors?: string[];
}

export const transformContentstackPropsToSectionProps = ({
  backgroundImageUrl,
  imageUrl,
  date,
  authors,
  url,
  ...otherProps
}: SectionArticleHeaderContentstackProps): SectionArticleHeaderProps => {
  return {
    link: url,
    backgroundImage: backgroundImageUrl ? (
      <ComponentImage src={backgroundImageUrl} objectFit="cover" alt="" />
    ) : undefined,
    media: imageUrl ? <ComponentImage src={imageUrl} objectFit="cover" alt="" /> : undefined,
    date: new Date(date),
    authors: !authors ? undefined : authors.map((name) => ({ name })),
    ...otherProps,
  };
};
