import styled from "styled-components";
import HexOutline from "../../../components/hex-outline";
import {
  COLOR_OUTLINE_LIGHT_GREY,
  MEDIA_MOBILE_ALL,
  MEDIA_NOT_MOBILE,
  MEDIA_MOBILE_MEDIUM,
} from "./../../../../lib/layouts/default/theme";
import { cssLocalizedLineHeight } from "../../../components/css";

export const Wrapper = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 860px;
  position: relative;
`;

export const ImageContainer = styled.div`
  position: relative;
  margin: 0 0 0 10px;
  align-self: flex-start;

  * {
    display: block;
    width: 100%;
  }
`;

export const BodyContainer = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  box-sizing: border-box;

  padding: 30px 40px;
  align-items: center;
`;

export const HighlightStrip = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 10px;
  left: 250px;
  height: 0;
  width: 120px;
  border: 2px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;

export const StyledHexOutline = styled(HexOutline)``;

const BREAKPOINT_DESKTOP = 860;
export const ResponsiveWrapper = styled(Wrapper)`
  ${ImageContainer} {
    flex: 0 0 200px;
    width: 200px;
  }

  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    ${ContentContainer} {
      margin: 0 20px;
    }

    ${BodyContainer} {
      padding: 22px 12px;
      font-size: 10px;
      letter-spacing: 0.06em;
      ${cssLocalizedLineHeight(14 / 10)}
    }

    ${HighlightStrip} {
      width: 40px;
      left: 112px;
    }
  }

  @media ${MEDIA_MOBILE_ALL} {
    ${BodyContainer} {
      flex: 2 1 auto;
    }
  }

  @media (max-width: 500px) {
    ${ContentContainer} {
      display: block;
    }

    ${ImageContainer} {
      float: left;
      width: 30%;
      min-width: 110px;
      padding: 0 10px 8px 0;
    }
  }
`;
