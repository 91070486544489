import styled, { css, keyframes } from "styled-components";
import { rem, easing, hoverEffect } from "../../../../../utils/style";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Plus, Minus } from "../../../../../assets/symbols";

import {
  COLOR_TEXT_BLACK,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_PRIMARY_BLUE,
  MEDIA_MOBILE_ALL,
} from "../../../../../layouts/default/theme";
import { cssLocalizedLineHeight } from "../../../../../components/css";

export const StyledAccordionItem = styled(AccordionItem)`
  padding: 30px 0;
  border-top: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;

export const StyledAccordionItemHeading = styled(AccordionItemHeading)``;

export const StyledAccordionItemButton = styled(AccordionItemButton)`
  position: relative;
  padding-right: 30px;
  font-size: 18px;
  ${cssLocalizedLineHeight(24 / 18)}
  letter-spacing: 0.05em;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  border: none;

  @media ${MEDIA_MOBILE_ALL} {
    font-size: 16px;
    letter-spacing: 0.04em;
  }
`;

const AccordionItemButtonIcon = css`
  position: absolute;
  right: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  fill: ${COLOR_PRIMARY_BLUE};

  @media ${MEDIA_MOBILE_ALL} {
    top: 6px;
  }
`;

const AccordionItemButtonIconPlus = styled.svg`
  ${AccordionItemButtonIcon}
  opacity: 1;

  ${StyledAccordionItemButton}[aria-expanded='true'] &,
  ${StyledAccordionItemButton}[aria-selected='true'] & {
    opacity: 0;
  }
`;

const AccordionItemButtonIconMinus = styled.svg`
  ${AccordionItemButtonIcon}
  opacity: 0;

  ${StyledAccordionItemButton}[aria-expanded='true'] &,
  ${StyledAccordionItemButton}[aria-selected='true'] & {
    opacity: 1;
  }
`;

export const StyledAccordionItemButtonIconPlus = AccordionItemButtonIconPlus.withComponent(Plus);
export const StyledAccordionItemButtonIconMinus = AccordionItemButtonIconMinus.withComponent(Minus);

export const Content = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

export const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  padding-right: 30px;
  margin-top: 5px;
  font-size: 18px;
  ${cssLocalizedLineHeight(30 / 18)}
  letter-spacing: 0.04em;
  overflow: hidden;
  max-height: 200px;
  opacity: 1;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

  /* NOTE 2019-10-17 jeremboo: Overwrite the hidden property to animate it */
  &[hidden] {
    display: block;
    max-height: 0 !important;
    opacity: 0;
  }

  @media ${MEDIA_MOBILE_ALL} {
    font-size: 14px;
    ${cssLocalizedLineHeight(20 / 14)}
    letter-spacing: 0.04em;
  }
`;
