type PCS_ENVIRONMENT = "test" | "preview" | "live";
// because JS can't do direct type validation
const allowedEnvs = ["test", "preview", "live"];
export default (): PCS_ENVIRONMENT => {
  const passedEnv = process.env.GATSBY_PCS_ENVIRONMENT as PCS_ENVIRONMENT;
  if (passedEnv && allowedEnvs.includes(passedEnv)) {
    return passedEnv;
  }
  return "live";
};
