import React from "react";
import Separator from "../../separator/hex-line";

import { Wrapper, SolidDivider, SolidDividerLine } from "./style";

export interface SectionArticleSeparatorProps {
  hexLine?: boolean;
  className?: string;
  custom?: boolean;
  image?: {
    url: string;
  };
}

const SectionArticleSeparator: React.FC<SectionArticleSeparatorProps> = ({
  hexLine = true,
  className,
  custom,
  image,
}) => {
  return (
    <Wrapper className={className}>
      {hexLine ? (
        <Separator></Separator>
      ) : custom && image && image.url ? (
        <img src={image.url} />
      ) : (
        <SolidDivider>
          <SolidDividerLine></SolidDividerLine>
        </SolidDivider>
      )}
    </Wrapper>
  );
};

export default SectionArticleSeparator;
