import styled, { css } from "styled-components";
import Author from "./components/author";
import { MOBILE_MARGIN } from "../../../layouts/default/theme";

export const AuthorList = styled.div`
  display: flex;

  .carousel {
    width: 100%;
  }

  &::before,
  &::after {
    content: "";
    margin: auto;
  }
`;

export const StyledAuthor = styled(Author)`
  flex: 0 0 260px;
  text-align: center;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 30px 20px 40px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0px;
  }
`;

const ResponsiveAuthorList = css`
  ${StyledAuthor} {
    min-width: 220px;
    margin-right: ${MOBILE_MARGIN}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

interface Props {
  numAuthors: number;
}

export const ResponsiveWrapper = styled.div<Props>`
  ${AuthorList} {
    margin: 0 auto;
    max-width: ${(props) => (props.numAuthors > 3 ? "100%" : "860px")};
    padding: 0 ${MOBILE_MARGIN}px;
  }

  ${(props) => (props.numAuthors > 3 ? ResponsiveAuthorList : null)}

  @media (max-width: 901px) {
    ${ResponsiveAuthorList}
  }
`;
