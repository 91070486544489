import React, { useState } from "react";
import { useInView } from "../../../utils/hooks";

import { SectionArticleAuthorListProps } from "./typings";
import { ResponsiveWrapper, AuthorList, StyledAuthor } from "./style";
import { AuthorProps } from "./components/author/typings";
import Carousel from "../../../components/carousel";

const SectionArticleAuthorList: React.FC<SectionArticleAuthorListProps> = ({ className, authorList }) => {
  const [intersectionRef, inView] = useInView({ threshold: 0 });

  return (
    <ResponsiveWrapper className={className} numAuthors={authorList.length}>
      <AuthorList ref={intersectionRef}>
        <Carousel className={"carousel"}>
          {authorList &&
            authorList.map((author: AuthorProps, index: number) => (
              <StyledAuthor
                inView={inView}
                transitionDelay={300 + index * 200}
                avatar={author.avatar}
                name={author.name}
                fullName={author.fullName}
                title={author.title}
                bio={author.bio}
                className={author.className}
                key={index}
              />
            ))}
        </Carousel>
      </AuthorList>
    </ResponsiveWrapper>
  );
};

export default SectionArticleAuthorList;
