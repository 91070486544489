import React from "react";

import Panel from "./components/panel";

import { Wrapper, StyledAccordion } from "./style";

export interface SectionArticleAccordionProps {
  sections: Array<{
    title: string;
    content: React.ReactElement;
  }>;
  className?: string;
}

const SectionArticleAccordion: React.FC<SectionArticleAccordionProps> = ({ sections, className }) => {
  return (
    <Wrapper className={className}>
      <StyledAccordion allowZeroExpanded={true} allowMultipleExpanded={true}>
        {sections.map((section, index) => (
          <Panel key={`acc_${index}`} title={section.title} content={section.content} />
        ))}
      </StyledAccordion>
    </Wrapper>
  );
};

export default SectionArticleAccordion;
