import React, { ReactElement, useState } from "react";

import { Wrapper, ImageContainer, InfoContainer, Title, Value, Caption } from "./style";
import ConditionalWrapper from "../../../../../components/conditional-wrapper";

export interface ImageGridItemProps {
  image: ReactElement;
  title?: string;
  value?: string;
  caption?: string;
  className?: string;
  external_link?: string;
  open_in_new_window?: boolean;
}

const ImageGridItem: React.FC<ImageGridItemProps> = ({
  image,
  title,
  value,
  caption,
  className,
  external_link,
  open_in_new_window,
}) => {
  const [dimensions, setDimensions] = useState<[number, number]>();
  image = React.cloneElement(image, {
    onLoad: (e: any) => {
      if (!(e instanceof Image)) return;
      setDimensions([e.naturalWidth, e.naturalHeight]);
    },
  });

  return (
    <ConditionalWrapper
      condition={!!external_link}
      wrapper={(children) => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={external_link}
          target={open_in_new_window ? "_blank" : undefined}
          rel={open_in_new_window ? "noreferrer" : undefined}
        >
          {children}
        </a>
      )}
    >
      <Wrapper className={className}>
        <ImageContainer dimensions={dimensions}>{image}</ImageContainer>
        {title || value || caption ? (
          <InfoContainer>
            {title ? <Title>{title}</Title> : null}
            {value ? <Value>{value}</Value> : null}
            {caption ? <Caption>{caption}</Caption> : null}
          </InfoContainer>
        ) : null}
      </Wrapper>
    </ConditionalWrapper>
  );
};

export default ImageGridItem;
