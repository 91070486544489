import React from "react";
import { SectionArticleHtmlProps } from "../.";
import { RichTextFieldType } from "../../../../../contentstack/fields/rich-text";
import richTextToReactElement from "../../../../../contentstack/utils/rich-text-to-react-element";

export interface SectionArticleHtmlContentstackProps {
  content: RichTextFieldType;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleHtmlContentstackProps,
): SectionArticleHtmlProps => {
  return {
    content: richTextToReactElement(props.content),
  };
};
