import React, { useContext, useEffect, useRef, useState } from "react";
import { SectionEventHeaderProps } from "../.";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";
import { TemplateContextBannerSettings } from "../../../../../contentstack/content-types/pages/templates/default/typings";
import { StyledYouTube, VideoWrapper, VideoWrapperInner, HtmlVideo } from "../style";
import RiotLocaleContext from "../../../../contexts/riot-locale";
import RiotLocale from "../../../../enums/riot-locale";
import { useInView, useMedia } from "../../../../utils/hooks";

export interface SectionEventHeaderContentstackProps extends Pick<SectionEventHeaderProps, "title" | "blurb"> {
  imageUrl?: ImageAssetUrl;
  bannerSettings?: TemplateContextBannerSettings;
}
type MediaComponentProps = Pick<SectionEventHeaderContentstackProps, "imageUrl" | "bannerSettings">;

const MediaComponent: React.FC<MediaComponentProps> = ({ imageUrl, bannerSettings }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [html5VideoUrl, setHtml5VideoUrl] = useState<string>("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useMedia(`(max-width: 800px)`, (match) => {
    setIsMobile(match);
  });

  useEffect(() => {
    if (isMobile && bannerSettings?.mobile_html5_video_url) {
      setHtml5VideoUrl(bannerSettings.mobile_html5_video_url);
    } else if (!isMobile && bannerSettings?.html5_video_url) {
      setHtml5VideoUrl(bannerSettings.html5_video_url);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (inView) videoRef.current.play();
    else if (!inView) videoRef.current.pause();
  }, [inView, videoRef.current]);

  const riotLocale = useContext(RiotLocaleContext) || RiotLocale.en_US;
  // Default object positions to 'center' for unspecified positions.
  const position = [
    (bannerSettings && bannerSettings.focal_point_x) || "center",
    (bannerSettings && bannerSettings.focal_point_y) || "center",
  ].join(" ");
  if (bannerSettings && bannerSettings.youtube_video_id && imageUrl) {
    return (
      <>
        <VideoWrapper>
          <VideoWrapperInner>
            <StyledYouTube
              playsInline={true}
              video={bannerSettings.youtube_video_id}
              autoplay={false}
              muted={false /* We only want to mute video on autoplay */}
              onReady={() => void 0}
              onPause={() => void 0}
              onPlaying={() => void 0}
              lang={riotLocale}
              allowFullscreen={true}
              annotations={false}
              modestBranding={true}
              showRelatedVideos={false}
            />
          </VideoWrapperInner>
        </VideoWrapper>
        <ComponentImage src={imageUrl} alt="" objectFit="cover" objectPosition={position} />
      </>
    );
  } else if (imageUrl) {
    return <ComponentImage src={imageUrl} alt="" objectFit="cover" objectPosition={position} />;
  } else if (bannerSettings?.html5_video_url || bannerSettings?.mobile_html5_video_url) {
    return (
      <div ref={ref}>
        <HtmlVideo key={html5VideoUrl} ref={videoRef} muted loop playsInline autoPlay>
          <source src={html5VideoUrl} type="video/mp4" />
        </HtmlVideo>
      </div>
    );
  } else {
    return null;
  }
};

export const transformContentstackPropsToSectionProps = ({
  imageUrl,
  bannerSettings,
  ...otherProps
}: SectionEventHeaderContentstackProps): SectionEventHeaderProps => {
  return {
    ...otherProps,
    media: <MediaComponent imageUrl={imageUrl} bannerSettings={bannerSettings} />,
  };
};
