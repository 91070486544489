import React from "react";
import { SectionArticleGalleryProps } from "../.";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ContentstackImage from "../../../../../contentstack/components/image";
import { THUMBNAIL_MAX_SIZE } from "../style";

export interface SectionArticleGalleryContentstackProps {
  startInZoomedMode?: boolean;
  title?: string;
  images: Array<{
    title?: string;
    url: ImageAssetUrl;
  }>;
}

export const transformContentstackPropsToSectionProps = ({
  images,
  ...otherProps
}: SectionArticleGalleryContentstackProps): SectionArticleGalleryProps => {
  return {
    ...otherProps,
    images: images.map(({ url, title }) => {
      return {
        caption: title,
        image: <ContentstackImage src={url} alt={title} objectFit="contain" />,
        thumbnail: (
          <ContentstackImage
            src={url}
            alt={title}
            crop="1:1"
            sizes={THUMBNAIL_MAX_SIZE + "px"}
            responsiveSizes={[
              { width: THUMBNAIL_MAX_SIZE },
              { width: THUMBNAIL_MAX_SIZE * 2 },
              { width: THUMBNAIL_MAX_SIZE * 3 },
            ]}
            objectFit="cover"
          />
        ),
      };
    }),
  };
};
