import styled, { css } from "styled-components";
import {
  COLUMN_WIDTH,
  COLOR_PRIMARY_BLUE,
  COLOR_PRIMARY_BLUE_ACTIVE,
  COLOR_OUTLINE_LIGHT_GREY,
  MEDIA_MOBILE_ALL,
  MOBILE_MARGIN,
} from "../../../layouts/default/theme";
import { rem, easing } from "../../../utils/style";
import { cssLocalizedUppercase } from "../../../components/css";

export const THUMBNAIL_MAX_SIZE = 200;

export const Wrapper = styled.div`
  margin-top: 100px;
`;

export const GalleryWrapper = styled.div`
  max-width: 960px;
  padding: 0 60px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding: 0 20px;
  }
`;

export const GalleryInner = styled.div`
  position: relative;
`;

export const GalleryFrame = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;
  min-height: 480px;
  margin-top: 10px;

  @media ${MEDIA_MOBILE_ALL} {
    height: 360px;
  }
`;

export const GalleryGrid = styled.div`
  display: block;
  overflow: auto;
  box-sizing: border-box;

  width: 100%;
  height: calc(100% - 40px);
  padding: 0 ${COLUMN_WIDTH - 10}px;
  margin-top: 40px;

  @media ${MEDIA_MOBILE_ALL} {
    height: calc(100% - 15px);
    padding: 0 7.5px;
    margin-top: 15px;
  }
`;

export const GalleryGridList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
`;

export const GalleryGridItem = styled.li`
  box-sizing: border-box;
  width: 20%;
  padding: 10px;
  overflow: hidden;

  @media (max-width: 1000px) {
    width: 25%;
  }
  @media ${MEDIA_MOBILE_ALL} {
    width: ${100 / 3}%;
    padding: 5px;
  }
`;

export const GalleryGridItemAnchor = styled.a`
  position: relative;

  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  cursor: pointer;
`;

export const GalleryGridItemImage = styled.span`
  position: absolute;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  transition: transform 0.8s ${easing.easeOutCubic};

  ${GalleryGridItemAnchor}:hover & {
    transform: scale3d(0.96, 0.96, 1);
    transition: transform 0.2s ${easing.easeOutCubic};
  }

  * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const GalleryDisplay = styled.figure`
  display: block;
  flex: 1 1 100%;
  width: 100%;
  height: calc(100% + 10px);
  margin: -10px ${COLUMN_WIDTH}px 0;

  @media ${MEDIA_MOBILE_ALL} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const GalleryDisplayItemImage = styled.button`
  width: 100%;
  height: 100%;
  overflow: hidden;

  border: 0;
  padding: 0;
  background-color: transparent;

  * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const GalleryDock = styled.aside`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px ${COLUMN_WIDTH}px;

  @media ${MEDIA_MOBILE_ALL} {
    flex-wrap: wrap;
    height: auto;
    padding: 0 ${MOBILE_MARGIN}px;
  }
`;

export const GalleryDockInfo = styled.div`
  box-sizing: border-box;
  padding-right: 20px;

  @media ${MEDIA_MOBILE_ALL} {
    width: 100%;
    padding-right: 0;
    padding-top: 15px;
    text-align: center;
  }
`;

export const GalleryDockInfoTitle = styled.h4`
  margin-top: 0;
  font-size: ${rem(20)};

  @media ${MEDIA_MOBILE_ALL} {
    font-size: ${rem(15)};
  }
`;

const GalleryDockInfoCount = css`
  margin-top: 6px;
  font-size: 10px;
  ${cssLocalizedUppercase}
  letter-spacing: 0.1em;

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: 4px;
    font-size: ${rem(10)};
  }
`;

export const GalleryDockInfoCountTotal = styled.p`
  ${GalleryDockInfoCount}

  display: block;
  ${GalleryWrapper}.is-zoomed & {
    display: none;
  }
`;

export const GalleryDockInfoCountCurrent = styled.p`
  ${GalleryDockInfoCount}

  display: none;
  ${GalleryWrapper}.is-zoomed & {
    display: block;
  }
`;

export const GalleryDockControls = styled.nav`
  display: flex;
  visibility: hidden;
  ${GalleryWrapper}.is-zoomed & {
    visibility: visible;
  }

  @media ${MEDIA_MOBILE_ALL} {
    width: 100%;
    padding: 10px 0 20px;
    justify-content: space-between;
  }
`;

export const GalleryDockButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0;

  display: block;
  width: ${rem(32)};
  height: ${rem(32)};
`;

export const GalleryDockButtonIcon = styled.svg`
  display: block;
  width: ${rem(20)};
  height: ${rem(20)};
  margin: ${rem(6)};

  fill: ${COLOR_PRIMARY_BLUE};
  transition: fill 0.2s ${easing.easeInOutCubic};

  ${GalleryDockButton}:hover & {
    fill: ${COLOR_PRIMARY_BLUE_ACTIVE};
  }
`;

// export const GalleryDockExpandButton = styled.button`

// `

export const GalleryDockNavigation = styled.div`
  white-space: nowrap;
  padding-left: 40px;
  border-left: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
  margin-left: 20px;

  @media ${MEDIA_MOBILE_ALL} {
    border: 0;
  }
`;

export const GalleryDockNavigationButton = styled.button`
  /* Reset */
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;

  /* Style */
  position: relative;

  width: ${rem(40)};
  height: ${rem(32)};
  margin-right: ${rem(5)};

  cursor: pointer;

  transition: opacity 0.2s ${easing.easeInOutCubic};

  &.is-disabled {
    opacity: 0.3;
    cursor: normal;
    pointer-events: none;
  }
`;

export const ArrowIcon = styled.svg`
  display: block;
  width: 40%;
  height: 100%;
  margin: 0 30%;

  fill: ${COLOR_PRIMARY_BLUE};
  stroke: ${COLOR_PRIMARY_BLUE};

  transition: fill 0.2s ${easing.easeInOutCubic}, stroke 0.2s ${easing.easeInOutCubic};

  ${GalleryDockNavigationButton}:hover & {
    fill: ${COLOR_PRIMARY_BLUE_ACTIVE};
    stroke: ${COLOR_PRIMARY_BLUE_ACTIVE};
  }
`;

export const BottomHighlight = styled.div`
  position: absolute;
  left: 38.5%;
  bottom: 0px;
  width: 21%;
  height: 1px;
  border-bottom: 3px solid ${COLOR_OUTLINE_LIGHT_GREY};
`;
